<template>
  <div class="vLogsTables">
    <vLightPreloader
      v-if="isLoading"
      :fixed="false"
      :transparent="true"
      :text="preloaderText"
    />
    <div v-if="!detail">
      <div class="vLogsTables__title">
        Список столиков
      </div>
      <div
        v-if="Array.isArray(GET_TABLES) && !GET_TABLES.length"
        class="vSectionQr__empty"
      >
        Не удалось получить список столов
      </div>
      <div
        v-else
        class="vLogsTables__header"
      >
        <p class="vLogsTables__header-title">
          Поиск столов
        </p>

        <div class="vLogsTables__header-inputs">
          <div class="vSectionQr__search">
            <div class="vSectionQr__search-inputsBlock">
              <div class="vSectionQr__search-inputs">
                <div class="vSectionQr__search-wrap">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_7064_211293)">
                      <path
                        d="M19 19L15.71 15.71M15.7138 6.8382C18.1647 9.28913 18.1647 13.2629 15.7138 15.7138C13.2629 18.1647 9.28913 18.1647 6.8382 15.7138C4.38727 13.2629 4.38727 9.28913 6.8382 6.8382C9.28913 4.38727 13.2629 4.38727 15.7138 6.8382V6.8382Z"
                        stroke="#80869A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7064_211293">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <input
                    v-model="range_from"
                    v-maska="'######'"
                    type="text"
                    class="vSectionQr__input"
                    placeholder="С:"
                    :class="{error: tableSearcError}"
                  >
                </div>

                <div class="vSectionQr__search-wrap">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_7064_211293)">
                      <path
                        d="M19 19L15.71 15.71M15.7138 6.8382C18.1647 9.28913 18.1647 13.2629 15.7138 15.7138C13.2629 18.1647 9.28913 18.1647 6.8382 15.7138C4.38727 13.2629 4.38727 9.28913 6.8382 6.8382C9.28913 4.38727 13.2629 4.38727 15.7138 6.8382V6.8382Z"
                        stroke="#80869A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7064_211293">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <input
                    v-model="range_to"
                    v-maska="'######'"
                    type="text"
                    class="vSectionQr__input"
                    placeholder="По:"
                    :class="{error: tableSearcError}"
                  >
                </div>
              </div>

              <span
                v-if="tableSearcError"
                class="vLogsTables__searchError"
              >
                {{ tableSearcError }}
              </span>
            </div>

            <button
              type="button"
              class="vSectionQr__btn"
              @click="search"
            >
              Найти
            </button>

            <button
              v-show="reset"
              type="button"
              class="vSectionQr__btn vSectionQr__btn--red"
              @click="resetSerch"
            >
              Сбросить
            </button>
          </div>

          <div class="vLogsTables__email">
            <div class="vLogsTables__email-inputWrapper">
              <input
                v-model="email"
                type="email"
                class="vSectionQr__input vLogsTables__email-input"
                placeholder="E-mail"
                :class="{error: emailError}"
              >

              <span
                v-if="emailError"
                class="vLogsTables__searchError"
              >
                {{ emailError }}
              </span>
            </div>

            <button
              type="button"
              class="vSectionQr__btn"
              @click="checkSearchInputs"
            >
              Скачать
            </button>
          </div>
        </div>
      </div>


      <div
        v-if="GET_TABLES.tables && !GET_TABLES.tables.length"
        class="vSectionQr__empty"
      >
        Ничего не найдено, попробуйте изменить параметры
      </div>

      <div
        v-else
      >
        <div
          v-if="downloading"
          class="vLogsTables__downloadPreloader"
        >
          <vLightPreloader
            :fixed="false"
            :transparent="true"
            :text="preloaderText"
          />
        </div>

        <ul class="vSectionQr__list">
          <li
            v-for="table in GET_TABLES.tables"
            :key="table.id"
            class="vSectionQr__item"
          >
            <div
              class="vSectionQr__item-info"
              @click="onDetailTable(table)"
            >
              <div class="vSectionQr__item-table">
                <span>Стол {{ table.code }}</span>

                <span
                  v-if="table.temporary_code"
                  class="vSectionQr__code"
                >
                  Код: {{ table.temporary_code }}
                </span>
              </div>

              <svg
                width="16"
                height="24"
                viewBox="0 0 16 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8335 16L14.8335 12L10.8335 8"
                  stroke="#80869A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <button
              class="vSectionQr__downloadBtn"
              @click="downloadBlackQr(table)"
            >
              <vIconDownload />
              Скачать
            </button>
          </li>
        </ul>
      </div>

      <vPargnation
        v-if="GET_TABLES.tables && GET_TABLES.tables.length"
        :pages="GET_TABLES.countPage"
        :page="GET_TABLES.pageCurrent"
        @onHandler="onClickPagination"
      />
    </div>

    <div v-if="detail">
      <vSectionQrDetail
        :table-id="tableId"
        :selected-table="selectedTable"
        @onDetail="onDetail"
        @downloadQr="downloadQr"
      />
    </div>

    <div
      v-show="searchLoading"
      class="vSectionQr__preloader"
    >
      <img
        src="~@/assets/images/preloader.gif"
        alt="Загрузка..."
      >
      <div>Ищем столик</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vPargnation from '@/components/v-pagination.vue'
import vSectionQrDetail from '@/components/profile/v-section-qr-detail'
import vLightPreloader from '@/components/v-light-preloader'
import vIconDownload from '@/assets/images/icons/v-icon-download.vue'
import axios from 'axios'
import RestaurantService from '@/services/restaurantService'
export default {
  name: 'VLogsTables',
  components: {
    vLightPreloader,
    vPargnation,
    vSectionQrDetail,
    vIconDownload
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: true,
      detail: false,
      range_from: '',
      range_to: '',
      reset: false,
      ableData: {},
      tableId: {},
      searchLoading: false,
      email: '',
      tableSearcError: '',
      emailError: '',
      preloaderText: '',
      downloading: false,
      selectedTable: {}
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('restaurant', ['GET_TABLES', 'GET_QR'])
  },
  async mounted() {
    await this.FETCH_TABLES({
      token: this.token,
      page: 1,
      shop_id: this.id,
    })
    setTimeout(() => {
      this.isLoading = false
    }, 500)
  },
  methods: {
    ...mapActions('restaurant', [
      'FETCH_TABLES',
      'DOWNLOAD_QR_CODE',
      'FETCH_SELECTED_TABLE',
    ]),
    ...mapActions('menu', ['CHAANGE_ACTIVE_PROFILE_MENU']),
    togglePreloader(status) {
      this.isLoading = status
    },
    async resetSerch() {
      this.searchLoading = true
      this.tableSearcError = ''
      await this.FETCH_TABLES({
        token: this.token,
        page: 1,
        shop_id: this.id,
      })
      this.range_from = ''
      this.range_to = ''
      this.reset = false
      this.searchLoading = false
    },
    async onClickPagination(num) {
      this.isLoading = true
      await this.FETCH_TABLES({
        page: num,
        range_from: this.range_from.length ? parseInt(this.range_from) : null,
        range_to: this.range_to.length ? parseInt(this.range_to) : null,
        token: this.token,
        shop_id: this.id,
      })
      this.isLoading = false
    },
    async search() {
      this.tableSearcError = ''
      if (this.range_from.length || this.range_to.length) {
        this.searchLoading = true
        await this.FETCH_TABLES({
          token: this.token,
          page: 1,
          range_from: this.range_from.length ? parseInt(this.range_from) : null,
          range_to: this.range_to.length ? parseInt(this.range_to) : null,
          shop_id: this.id,
        })
        this.reset = true
        this.searchLoading = false
      }
    },
    onDetail() {
      this.detail = !this.detail
    },

    toggleId(table) {
      this.tableId = table.id
    },

    onDetailTable(table) {
      this.toggleId(table)
      this.fetchQr(table).then(() => this.onDetail())
    },

    async fetchQr(table) {
      this.togglePreloader(true)
      this.toggleId(table)

      const table_id = this.tableId

      if (!table_id) {
        this.CHAANGE_ACTIVE_PROFILE_MENU({
          name: 'Столики и QR-коды',
          code: 'qr',
        })
        await this.$router.push('/profile')
        return
      }

      try {
        let shop_id = ''

        if (this.id.toString().length) {
          shop_id = '?shop_id=' + this.id.toString()
        }

        const getTable = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/handbook/table/${table_id}${shop_id}`,
          {
            headers: {
              Authorization: 'Bearer ' + this.GET_AUTHENTIFICATED,
            },
          }
        )
        this.selectedTable = Array.isArray(getTable.data?.table)
          ? getTable.data?.table[0]
          : getTable.data?.table

        if (!shop_id.length) {
          this.CHAANGE_ACTIVE_PROFILE_MENU({
            name: 'Столики и QR-коды',
            code: 'qr',
          })
        }
        this.togglePreloader(false)
      } catch (e) {
        console.error('error', e)
        this.togglePreloader(false)
      }
    },

    async downloadQr(type) {
      axios({
        url:
          type === 'qr'
            ? this.selectedTable?.qr
            : this.selectedTable?.qr_invert,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]))
        let fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          'table' + this.selectedTable?.code + '.png'
        )
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },

    downloadBlackQr(table) {
      this.fetchQr(table).then(() => this.downloadQr())
    },

    checkSearchInputs() {
      this.emailError = ''
      this.tableSearcError = ''
      let err = false
      if (!this.email) {
        err = true
        this.emailError = 'Введите E-mail'
      } else {
        const regexp = /(^[aA-zZ0-9])([^@\{}!#()\s]*)(@)([^@\{}!#()\s]*)(\.)([aA-zZ]{2,9}$)/
        const res = regexp.test(this.email)
        if (!res) {
          err = true
          this.emailError = 'Введите корректный E-mail'
        }
      }
      if (!this.range_from && !this.range_to) {
        err = true
        this.tableSearcError = 'Введите диапазон для скачивания'
      }
      if (!err) {
        this.preloaderText = 'На указанную почту придет архив в течение 5 минут'
        this.downloading = true

        const payload = {
          range_from: this.range_from,
          range_to: this.range_to,
          shop_id: this.id,
          email: this.email
        }

        RestaurantService.downloadFewQr(payload)
        setTimeout(() => {
          this.preloaderText = ''
          this.downloading = false
        }, 1000)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.vLogsTables {
  position: relative;
  min-height: calc(100vh - 375px);
  &__header {
    margin-bottom: 32px;

    &-title {
      margin-bottom: 12px;
    }

    &-inputs {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1224px;
      gap: 12px;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  &__email {
    display: flex;
    flex-basis: 410px;
    gap: 12px;

    @media (max-width: 880px) {
      flex-direction: column;
    }

    &-inputWrapper {
      width: 100%;
    }

    &-input {
      padding: 0 12px
    }

    &-btn {
      padding: 0 18px;
      border: none;
      border-radius: 6px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #fff;
      background: linear-gradient(225deg, #547aff 0%, #413dff 100%);
    }
  }

  &__searchError {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding-left: 12px;
    color: #ec4e4e;
  }
}
</style>
