import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vModal__content" }
const _hoisted_2 = { class: "vModal__header" }
const _hoisted_3 = { class: "vModal__title" }
const _hoisted_4 = { class: "vModal__close" }
const _hoisted_5 = { class: "vModal__body" }
const _hoisted_6 = { class: "vModal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vCloseButton = _resolveComponent("vCloseButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vModal", { 'vModal--botton': _ctx.styleBottom }])
  }, [
    _createElementVNode("div", {
      class: "vModal__overlay",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "title")
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createVNode(_component_vCloseButton, { onHandleClick: _ctx.closeModal }, null, 8, ["onHandleClick"])
        ], 512), [
          [_vShow, _ctx.closeBtn]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "body")
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "footer")
      ])
    ])
  ], 2))
}