import { $authHost } from './api'
import { AxiosResponse } from 'axios'

interface IFetchTypeResponse {
  success: boolean
  result: {
    selected: string,
    options: string[]
  }
}

class TipsTypeService {
  async fetch(id: number): Promise<IFetchTypeResponse> {
    const { data }:AxiosResponse<IFetchTypeResponse> = await $authHost.get(`/support/shop/advance-check-type/${id}`)
    return data
  }

  async save(id: number, type: string) {
    await $authHost.post('/support/shop/advance-check-type/', {
      shop_id: id,
      type
    })
  }
}



export default new TipsTypeService()
