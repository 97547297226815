import { $authHost, $host } from './api'
import { AxiosResponse } from 'axios'
import { IFetchDivideCheck, IChangeDivideCheck } from '@/types/divideCheckTypes'

class DivideCheckService {
  async fetch(id: number) {
    const { data }: AxiosResponse<IFetchDivideCheck> = await $host.get(`/shop/${id}/diff-pay`)
    return data
  }

  async changeDivideCheck(id: number, token: string, diff_pay: boolean) {
    const { data }: AxiosResponse<IChangeDivideCheck> = await $authHost.put(`/support/shop/${id}/diff-pay`,
    {
      diff_pay: diff_pay
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    return data
  }
}

export default new DivideCheckService()
