import axios from 'axios';
import { AxiosResponse } from 'axios'
import { ISuggestionsResponse, ISuggestionResponse, ISuggestion } from '@/types/suggestions'

interface IProps {
  query: string
}

class SuggestionsService {
  async getSuggestions(suggestion: IProps) {
    const count = 10;
    const token = "03d073b73111aa24eea38ff6c2373f694cc3a6c8";
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';

    const payload = {
      query: suggestion.query,
      count,
      from_bound: { "value": "city" },
      to_bound: { "value": "city" }
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    const {
      data: { suggestions },
    }: AxiosResponse<ISuggestionsResponse> = await axios.post(url, payload, config);

    return this.transformGetSuggestionsResponse(suggestions);
  }

  private transformGetSuggestionsResponse (data: ISuggestionResponse[]):ISuggestion[] {
    const res = data.map((item) => {
      return {
        value: item.value,
        city: item.data.city,
        geo_lat: item.data.geo_lat,
        geo_lon: item.data.geo_lon
      }
    })
    return res
  }
}


export default new SuggestionsService()
