import { defineStore } from 'pinia'
import { ref } from 'vue'
import { AxiosError } from 'axios'
import DivideCheckService from '@/services/divideCheckService'

export const useDivideCheckStore = defineStore('divideCheckStore', () => {
  const divideCheck = ref(true)

  const fetchDivideCheck = async (id: number) => {
    try {
      const response = await DivideCheckService.fetch(id)
      divideCheck.value = response.diff_pay
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('fetchDivideCheckError', error.response);
      console.log('====================================');
    }
  }

  const changeDivideCheck = async (id: number, token: string, diff_pay: boolean) => {
    try {
      const response = await DivideCheckService.changeDivideCheck(id, token, diff_pay)
      divideCheck.value = response.result.diff_pay
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('fetchDivideCheckError', error.response);
      console.log('====================================');
    }
  }

  return {
    divideCheck,
    fetchDivideCheck,
    changeDivideCheck
  }
})
