import { $authHost } from './api'
import { AxiosResponse } from 'axios'
import { IFeeTypeResponse, TFeeType } from '@/types/feeTypes'

interface IChangeTypeResponse {
  success: boolean
}

class FeeTypeService {
  async fetch(id: number, token: string): Promise<IFeeTypeResponse> {
    const { data }:AxiosResponse<IFeeTypeResponse> = await $authHost.get('/admin-panel/fee-type', {
      params: {
        restaurant_id: id
      },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    return data
  }

  async changeType(id: number, token: string, feeType: TFeeType) {
    const query = {
      restaurant_id: id,
      fee_type: feeType
    }
    const { data }:AxiosResponse<IChangeTypeResponse> = await $authHost.put('/admin-panel/fee-type',
    query,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    return data
  }
}

export default new FeeTypeService()
