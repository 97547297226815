import { defineStore } from 'pinia'
import { ref } from 'vue'
import { AxiosError } from 'axios'
import SuggestionsService from '@/services/suggestions'
import { ISuggestion } from '@/types/suggestions'

export const useSuggestionsStore = defineStore('suggestionsStore', () => {
  const suggestions = ref<ISuggestion[]>([])
  const suggestionsError = ref<string>('')

  const fetchSuggestions = async (val: string) => {
    try {
      const response: ISuggestion[] = await SuggestionsService.getSuggestions({query: val})
      if (!response.length && val.length) {
        suggestionsError.value = ('Введите корректное название города')
      } else {
        suggestionsError.value = ('')
      }
      suggestions.value = response
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('fetchSuggestionsError', error.response);
      console.log('====================================');
    }
  }

  const clearSuggestions = () => {
    suggestions.value = []
  }

  return {
    suggestions,
    suggestionsError,
    fetchSuggestions,
    clearSuggestions
  }
})
