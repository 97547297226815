import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c51a876c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vLogsTwoAcquiring" }
const _hoisted_2 = { class: "vLogsTwoAcquiring__entity" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vLightPreloader = _resolveComponent("vLightPreloader")!
  const _component_VIconMinusInCircle = _resolveComponent("VIconMinusInCircle")!
  const _component_vInput = _resolveComponent("vInput")!
  const _component_vSelect = _resolveComponent("vSelect")!
  const _component_vButton = _resolveComponent("vButton")!
  const _component_VModalCloseEnitity = _resolveComponent("VModalCloseEnitity")!
  const _component_VModalConfirmChanges = _resolveComponent("VModalConfirmChanges")!
  const _component_VModalConfirmationChangesDetails = _resolveComponent("VModalConfirmationChangesDetails")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_vLightPreloader, {
        fixed: false,
        transparent: true
      }, null, 512), [
        [_vShow, _ctx.isLoading]
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(" Второе юридическое лицо "),
        _createVNode(_component_VIconMinusInCircle, {
          class: "vLogsTwoAcquiring__entity-icon",
          onClick: _ctx.toggleModalCloseEnitity
        }, null, 8, ["onClick"])
      ]),
      (_ctx.selectPlatform.name === 'Best2pay')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_vInput, {
              id: "Best2pay",
              modelValue: _ctx.Best2pay.accNumber,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.Best2pay.accNumber) = $event)),
              value: _ctx.Best2pay.accNumber,
              required: true,
              rule: 'min:1',
              placeholder: "acc number",
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hasChanges = true))
            }, null, 8, ["modelValue", "value"]),
            _createVNode(_component_vSelect, {
              values: _ctx.taxAll,
              data: _ctx.Best2pay.nds.name,
              "outside-click-data": _ctx.outsideClickData,
              element: "Best2pay",
              placeholder: "НДС",
              onSelectValue: _ctx.selectValue
            }, null, 8, ["values", "data", "outside-click-data", "onSelectValue"]),
            _createVNode(_component_vSelect, {
              values: _ctx.taxations,
              data: _ctx.Best2pay.taxations.name,
              "outside-click-data": _ctx.outsideClickData,
              element: "Best2pay",
              placeholder: "Система налогообложения",
              onSelectValue: _ctx.selectTaxations
            }, null, 8, ["values", "data", "outside-click-data", "onSelectValue"]),
            (_ctx.GET_PLATFORMS_DATA?.data?.name === 'r-keeper')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_vInput, {
                    id: "Best2pay",
                    modelValue: _ctx.Best2pay.classId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.Best2pay.classId) = $event)),
                    value: _ctx.Best2pay.classId,
                    required: true,
                    rule: 'min:1',
                    placeholder: "ID классификации",
                    onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hasChanges = true))
                  }, null, 8, ["modelValue", "value"]),
                  _createVNode(_component_vInput, {
                    id: "Best2pay",
                    modelValue: _ctx.Best2pay.groupId,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.Best2pay.groupId) = $event)),
                    value: _ctx.Best2pay.groupId,
                    required: true,
                    rule: 'min:1',
                    placeholder: "ID группы",
                    onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hasChanges = true))
                  }, null, 8, ["modelValue", "value"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.GET_PLATFORMS_DATA?.data?.name === 'iiko')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_vInput, {
                    id: "Best2pay",
                    modelValue: _ctx.Best2pay.salePoint,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.Best2pay.salePoint) = $event)),
                    value: _ctx.Best2pay.salePoint,
                    required: true,
                    rule: 'min:1',
                    placeholder: "Название точки продаж"
                  }, null, 8, ["modelValue", "value"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_vButton, {
              type: "submit",
              disabled: _ctx.disButtonBest2pay,
              class: "btn-top",
              onClick: _ctx.toggleConfirmModal
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Сохранить ")
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]),
            (_ctx.error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Ошибка сохранения данных "))
              : _createCommentVNode("", true)
          ], 64))
        : (_ctx.selectPlatform.name === 'BEST2PAY_BARREL')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_vInput, {
                id: "BEST2PAY_BARREL",
                modelValue: _ctx.BEST2PAY_BARREL.accNumber,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.BEST2PAY_BARREL.accNumber) = $event)),
                value: _ctx.BEST2PAY_BARREL.accNumber,
                required: true,
                rule: 'min:1',
                placeholder: "acc number",
                onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.hasChanges = true))
              }, null, 8, ["modelValue", "value"]),
              _createVNode(_component_vSelect, {
                values: _ctx.taxAll,
                data: _ctx.BEST2PAY_BARREL.nds.name,
                "outside-click-data": _ctx.outsideClickData,
                element: "BEST2PAY_BARREL",
                placeholder: "НДС",
                onSelectValue: _ctx.selectValue
              }, null, 8, ["values", "data", "outside-click-data", "onSelectValue"]),
              _createVNode(_component_vSelect, {
                values: _ctx.taxations,
                data: _ctx.BEST2PAY_BARREL.taxations.name,
                "outside-click-data": _ctx.outsideClickData,
                element: "BEST2PAY_BARREL",
                placeholder: "Система налогообложения",
                onSelectValue: _ctx.selectTaxations
              }, null, 8, ["values", "data", "outside-click-data", "onSelectValue"]),
              (_ctx.GET_PLATFORMS_DATA?.data?.name === 'r-keeper')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_vInput, {
                      id: "BEST2PAY_BARREL",
                      modelValue: _ctx.BEST2PAY_BARREL.classId,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.BEST2PAY_BARREL.classId) = $event)),
                      value: _ctx.BEST2PAY_BARREL.classId,
                      required: true,
                      rule: 'min:1',
                      placeholder: "ID классификации",
                      onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.hasChanges = true))
                    }, null, 8, ["modelValue", "value"]),
                    _createVNode(_component_vInput, {
                      id: "BEST2PAY_BARREL",
                      modelValue: _ctx.BEST2PAY_BARREL.groupId,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.BEST2PAY_BARREL.groupId) = $event)),
                      value: _ctx.BEST2PAY_BARREL.groupId,
                      required: true,
                      rule: 'min:1',
                      placeholder: "ID группы",
                      onInput: _cache[12] || (_cache[12] = ($event: any) => (_ctx.hasChanges = true))
                    }, null, 8, ["modelValue", "value"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.GET_PLATFORMS_DATA?.data?.name === 'iiko')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_vInput, {
                      id: "BEST2PAY_BARREL",
                      modelValue: _ctx.BEST2PAY_BARREL.salePoint,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.BEST2PAY_BARREL.salePoint) = $event)),
                      value: _ctx.BEST2PAY_BARREL.salePoint,
                      required: true,
                      rule: 'min:1',
                      placeholder: "Название точки продаж",
                      onInput: _cache[14] || (_cache[14] = ($event: any) => (_ctx.hasChanges = true))
                    }, null, 8, ["modelValue", "value"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_vButton, {
                type: "submit",
                disabled: _ctx.disButtonBarrel,
                onClick: _ctx.toggleConfirmModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Сохранить ")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              (_ctx.error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Ошибка сохранения данных "))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createVNode(_component_vInput, {
                id: "CloudPayments",
                modelValue: _ctx.CloudPayments.accNumber,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.CloudPayments.accNumber) = $event)),
                value: _ctx.CloudPayments.accNumber,
                required: true,
                rule: 'min:1',
                placeholder: "acc number",
                onInput: _cache[16] || (_cache[16] = ($event: any) => (_ctx.hasChanges = true))
              }, null, 8, ["modelValue", "value"]),
              _createVNode(_component_vSelect, {
                values: _ctx.taxAll,
                data: _ctx.CloudPayments.nds.name,
                "outside-click-data": _ctx.outsideClickData,
                element: "CloudPayments",
                placeholder: "НДС",
                onSelectValue: _ctx.selectValue
              }, null, 8, ["values", "data", "outside-click-data", "onSelectValue"]),
              _createVNode(_component_vSelect, {
                values: _ctx.taxations,
                data: _ctx.CloudPayments.taxations.name,
                "outside-click-data": _ctx.outsideClickData,
                element: "CloudPayments",
                placeholder: "Система налогообложения",
                onSelectValue: _ctx.selectTaxations
              }, null, 8, ["values", "data", "outside-click-data", "onSelectValue"]),
              (_ctx.GET_PLATFORMS_DATA?.data?.name === 'iiko')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_vInput, {
                      id: "CloudPayments",
                      modelValue: _ctx.CloudPayments.salePoint,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.CloudPayments.salePoint) = $event)),
                      value: _ctx.CloudPayments.salePoint,
                      required: true,
                      rule: 'min:1',
                      placeholder: "Название точки продаж",
                      onInput: _cache[18] || (_cache[18] = ($event: any) => (_ctx.hasChanges = true))
                    }, null, 8, ["modelValue", "value"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_vButton, {
                type: "submit",
                disabled: 
          !_ctx.CloudPayments.accNumber ||
            !_ctx.CloudPayments.accNumber.length ||
            !_ctx.CloudPayments.salePoint ||
            !_ctx.CloudPayments.salePoint.length ||
            !_ctx.hasChanges
        ,
                onClick: _ctx.toggleConfirmModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Сохранить ")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              (_ctx.error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, " Ошибка сохранения данных "))
                : _createCommentVNode("", true)
            ], 64)),
      _createElementVNode("div", null, [
        _createVNode(_component_vInput, {
          id: "nameOrganization",
          modelValue: _ctx.objectInput.nameOrganization,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.objectInput.nameOrganization) = $event)),
          value: _ctx.objectInput.nameOrganization,
          type: "text",
          required: true,
          placeholder: "Полное наименование организации"
        }, null, 8, ["modelValue", "value"]),
        _createVNode(_component_vInput, {
          id: "inn",
          modelValue: _ctx.objectInput.inn,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.objectInput.inn) = $event)),
          value: _ctx.objectInput.inn,
          placeholder: "ИНН",
          type: "number",
          required: true,
          rules: "min:10|max:12",
          mask: "############"
        }, null, 8, ["modelValue", "value"]),
        _createVNode(_component_vInput, {
          id: "phone",
          modelValue: _ctx.objectInput.phone,
          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.objectInput.phone) = $event)),
          value: _ctx.objectInput.phone,
          placeholder: "Телефон",
          required: true,
          rules: "phone"
        }, null, 8, ["modelValue", "value"]),
        _createVNode(_component_vButton, {
          type: "submit",
          disabled: 
          _ctx.requisitesDisabled ||
            !_ctx.objectInput.nameOrganization.length ||
            _ctx.objectInput.inn.length < 9 ||
            _ctx.objectInput.inn.length === 9 ||
            !_ctx.objectInput.phone.length ||
            _ctx.objectInput.phone.length < 17
        ,
          onClick: _ctx.onModalChangingDetails
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Сохранить ")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    (_ctx.modalCloseEnitity)
      ? (_openBlock(), _createBlock(_component_VModalCloseEnitity, {
          key: 0,
          onCloseEntity: _ctx.closeEntity,
          onCloseModal: _ctx.toggleModalCloseEnitity
        }, null, 8, ["onCloseEntity", "onCloseModal"]))
      : _createCommentVNode("", true),
    (_ctx.confirmModal)
      ? (_openBlock(), _createBlock(_component_VModalConfirmChanges, {
          key: 1,
          onCloseModal: _ctx.toggleConfirmModal,
          onConfirmChanges: _ctx.checkForm
        }, null, 8, ["onCloseModal", "onConfirmChanges"]))
      : _createCommentVNode("", true),
    (_ctx.modalChangingDetails)
      ? (_openBlock(), _createBlock(_component_VModalConfirmationChangesDetails, {
          key: 2,
          onSavingDetailsInstitution: _ctx.savingDetailsInstitution,
          onOnModalChangingDetails: _ctx.onModalChangingDetails
        }, null, 8, ["onSavingDetailsInstitution", "onOnModalChangingDetails"]))
      : _createCommentVNode("", true)
  ], 64))
}