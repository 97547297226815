<template>
  <div class="vDevider"></div>
</template>

<style lang="scss" scoped>
.vDevider {
  height: 1px;
  width: 100%;
  background: rgba(237, 238, 241, 1);
  margin-bottom: 16px;
}
</style>
