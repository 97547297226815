interface IDate {
  startDate: string
  endDate: string
}

export const filterWeak = (): IDate => {
  const startDate = new Date()
  startDate.setDate(startDate.getDate() - 7)

  const newStartDate = startDate
    .toLocaleString('ru-RU', { dateStyle: 'short' })
    .split('.')
    .reverse()
    .join('-')

  const endDate = new Date()

  const newEndDate = endDate
    .toLocaleString('ru-RU', { dateStyle: 'short' })
    .split('.')
    .reverse()
    .join('-')

  const dates = {
    startDate: newStartDate,
    endDate: newEndDate,
  }

  return dates
}

export const filterMonth = (): IDate => {
  const startDate = new Date()
  startDate.setDate(1)

  const newStartDate = startDate
    .toLocaleString('ru-RU', { dateStyle: 'short' })
    .split('.')
    .reverse()
    .join('-')

  const endDate = new Date()

  const newEndDate = endDate
    .toLocaleString('ru-RU', { dateStyle: 'short' })
    .split('.')
    .reverse()
    .join('-')

  const dates = {
    startDate: newStartDate,
    endDate: newEndDate,
  }

  return dates
}

export const filterYear = (): IDate => {
  const endDate = new Date()

  const year = endDate.getFullYear()

  const month =
    endDate.getMonth() + 1 < 10
      ? '0' + (endDate.getMonth() + 1)
      : endDate.getMonth() + 1
  const day =
    endDate.getDate() < 10
      ? '0' + endDate.getDate()
      : endDate.getDate()
  const newEndDateArr = `${year}-${month}-${day}`

  const newStartDateArr = `${year}-01-01`

  const dates = {
    startDate: newStartDateArr,
    endDate: newEndDateArr,
  }

  return dates
}

export const filterPeriod = (period: Date[]): IDate => {
  const periodFrom = period[0].toLocaleString('ru-RU', { dateStyle: 'short' })

  const periodTo = period[1].toLocaleString('ru-RU', { dateStyle: 'short' })

  const dates = {
    startDate: periodFrom,
    endDate: periodTo,
  }

  return dates
}
