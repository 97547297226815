import { $authHost } from './api'
import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'

interface IDowloadFewQrResponse {
  success: boolean
}

interface IPayload {
  range_from: string
  range_to: string
  shop_id: number
  email: string
}

class RestaurantService {
  async downloadFewQr(payload: AxiosRequestConfig<IPayload>) {

    try {
      const { data }: AxiosResponse<IDowloadFewQrResponse> = await $authHost.get('/handbook/tables-download', {
        params: payload
      })

      return data.success
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('downloadFewQrError', error.response);
      console.log('====================================');
    }

  }
}

export default new RestaurantService()
