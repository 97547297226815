
import { defineComponent, PropType } from 'vue'
import vCloseButton from '@/components/v-close-button.vue'
import vButton from '@/components/v-button.vue'
export default defineComponent ({
  name: 'VModalCloud',
  components: {
    vCloseButton,
    vButton,
  },
  props: {
    summ: {
      type: Number as PropType<number>,
      require: true,
      default: 0,
    },
    token: {
      type: String as PropType<string>,
      required: true,
      default: '',
    },
  },
  emits: [
    'toggleConfirm',
    'cancellationInvitation',
    'checkButton',
    'onVModalCloud',
    'toggleModal',
  ],
  computed: {},
  async mounted() {},
  methods: {
    payout(): void {
      this.$emit('toggleModal')
    },

    toggleConfirm(): void {
      this.$emit('toggleConfirm')
    },

    cancellationInvitation(): void {
      this.$emit('cancellationInvitation')
    },

    checkButton(): void {
      this.$emit('checkButton')
    },

    onVModalCloud(): void {
      this.$emit('onVModalCloud')
    },
  },
})
