import { $authHost } from './api'
import { AxiosResponse, AxiosError } from 'axios'
import {
  IGetLoyaltySystemResponse,
  IGetLoyaltySystem,
  IGetTokenPrimeHillResponse,
  IGetPrimeHillType,
  IGetPrimeHillTypeResponse,
  TPrimeHillType,
  TVerificationPrimeHill,
  IGetVerificationPrimeHill
} from '@/types/loyaltyTypes'

interface ISetLoyaltyPayload {
  token: string
  id: number
  code: string
}

interface ISetTokenPrimeHillPayload {
  token: string
  id: number
  tokenPrimeHill: string
}

interface IUpdatePrimeHillTypePayload {
  id: number
  type: TPrimeHillType
  token: string
}

interface IResponse {
  success: boolean
  result: boolean
}

interface ISetVerificationPrimeHill {
  token: string
  id: number
  type: TVerificationPrimeHill
}

class LoyaltyService {
  async setLoyaltySystem(payload: ISetLoyaltyPayload): Promise<boolean> {
    const { token, id, code } = payload
    try {
      const { data }: AxiosResponse<IResponse> = await $authHost.post('support/loyalty/set-loyalty-system-to-shop',
      {
        shop_id: id,
        code: code
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      return data.result
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('setLoyaltySystemERROR', error.response);
      console.log('====================================');
      throw error
    }
  }

  async getLoyaltySystem(id: number, token: string): Promise<IGetLoyaltySystem> {
    const { data }: AxiosResponse<IGetLoyaltySystemResponse> = await $authHost.get(`support/loyalty/get-loyalty-system-by-shop-id/${id}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    )
    return data.result
  }

  async setTokenPrimeHill(payload: ISetTokenPrimeHillPayload): Promise<boolean> {
    const { token, id, tokenPrimeHill } = payload
    try {
      const { data }: AxiosResponse<IResponse> = await $authHost.post('support/loyalty/set-token-prime-hill-to-shop',
      {
        shop_id: id,
        token: tokenPrimeHill
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      return data.result
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('setTokenPrimeHillERROR', error.response);
      console.log('====================================');
      throw error
    }
  }

  async getTokenPrimeHill(id: number, token: string): Promise<IGetTokenPrimeHillResponse> {
    try {
      const { data }: AxiosResponse<IGetTokenPrimeHillResponse> = await $authHost.get(`support/loyalty/prime-hill-token-by-shop-id/${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return data
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('getTokenPrimeHillERROR', error.response?.data);
      console.log('====================================');
      throw error
    }
  }

  async updatePrimeHillType(payload: IUpdatePrimeHillTypePayload): Promise<boolean> {
    const { id, type, token } = payload
    try {
      const { data }: AxiosResponse<IResponse> = await $authHost.post('support/loyalty/update-prime-hill-type-write-off',
      {
        shop_id: id,
        type
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      return data.result
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('updatePrimeHillTypeERROR', error.response);
      console.log('====================================');
      throw error
    }
  }

  async getPrimeHillType(id: number, token: string): Promise<IGetPrimeHillType | null> {
    const { data }: AxiosResponse<IGetPrimeHillTypeResponse> = await $authHost.get(`support/loyalty/get-prime-hill-type-write-off/${id}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    )
    return data.result
  }

  async setVerificationPrimeHill(payload: ISetVerificationPrimeHill): Promise<boolean> {
    const { token, id, type } = payload
    try {
      const { data }: AxiosResponse<IResponse> = await $authHost.post('support/loyalty/update-prime-hill-verification',
      {
        shop_id: id,
        verification: type
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      return data.result
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('setVerificationPrimeHillERROR', error.response);
      console.log('====================================');
      throw error
    }
  }

  async getVerificationPrimeHill(id: number, token: string): Promise<TVerificationPrimeHill> {
    const { data }: AxiosResponse<IGetVerificationPrimeHill> = await $authHost.get(`support/loyalty/get-prime-hill-verification/${id}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return data.result
  }
}

export default new LoyaltyService()
