<template>
  <div class="vLogsLicenseRkeeper">
    <vLightPreloader
      v-if="isLoading"
      :fixed="false"
      :transparent="true"
    />

    <form class="section-profile__form typeLicense">
      <h3 class="typeLicense__title">
        Вид лицензии
      </h3>
      <div
        v-if="error"
        class="err"
      >
        {{ error }}
      </div>
      <div class="typeLicense__field">
        <vRadioButton
          id="XML_APPLICATION"
          text="XML интерфейс для приложения"
          :value="licenseType"
          name="XML"
          @changeData="changeXMLType"
        />
      </div>
      <div class="typeLicense__field">
        <vRadioButton
          id="XML_ORDER_SAVE"
          text="XML сохранение заказов (new)"
          :value="licenseType"
          name="XML"
          @changeData="changeXMLType"
        />
      </div>
      <div class="typeLicense__button">
        <vButton
          type="button"
          :fix-width="true"
          :disabled="disabledSaveLicenseType"
          @handleClick="toggleConfirmModal"
        >
          Сохранить
        </vButton>
      </div>
    </form>

    <form class="section-profile__form">
      <!-- id Лицензии -->
      <vInput
        v-if="licenseType !== 'XML_ORDER_SAVE'"
        id="licenseId"
        v-model="licenseId"
        :value="licenseId"
        placeholder="ID лицензии"
        :disabled="true"
      />

      <!-- Cрок окончания лицензии -->
      <vInput
        id="licenseDate"
        v-model="licenseDate"
        :value="licenseDate"
        placeholder="Cрок окончания лицензии"
        :disabled="true"
      />

      <vButton
        type="button"
        :fix-width="true"
        @handleClick="updateLicense"
      >
        Получить
        {{ licenseType === 'XML_APPLICATION' ? 'id' : 'срок' }} лицензии
      </vButton>
    </form>

    <VModalConfirmChanges
      v-if="confirmModal"
      @closeModal="toggleConfirmModal"
      @confirmChanges="saveXMLType"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vRadioButton from '@/components/v-radio-button'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import vLightPreloader from '@/components/v-light-preloader'
import VModalConfirmChanges from '@/components/modals/v-modal-confirm-changes'
export default {
  name: 'VLogsLicenseRkeeper',
  components: {
    vInput,
    vButton,
    vLightPreloader,
    vRadioButton,
    VModalConfirmChanges,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      licenseId: '',
      licenseDate: '',
      isLoading: true,
      licenseType: null,
      disabledSaveLicenseType: true,
      error: null,
      confirmModal: false,
    }
  },
  computed: {
    ...mapGetters('logs', ['LICENSE_DATA']),
  },
  async mounted() {
    this.isLoading = true
    const licenseType = await this.FETCH_LICENSE_TYPE({
      token: this.token,
      id: this.id,
    })
    this.licenseType = licenseType
    const license = await this.FETCH_LICENSE({ token: this.token, id: this.id })
    if (!license) {
      this.error = 'not r-keeper platform'
    }
    if (this.LICENSE_DATA.id_license) {
      this.licenseId = this.LICENSE_DATA.id_license
      this.licenseDate = this.LICENSE_DATA.date_expiration_license
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions('logs', [
      'UPDATE_ID_LICENSE',
      'FETCH_LICENSE',
      'FETCH_LICENSE_TYPE',
      'FETCH_END_DATA_LICENSE',
      'SAVE_LICENSE',
    ]),
    changeXMLType(val) {
      this.licenseType = val
      this.disabledSaveLicenseType = false
    },
    async saveXMLType() {
      this.toggleConfirmModal()
      this.isLoading = true
      this.error = ''
      this.disabledSaveLicenseType = true
      await this.SAVE_LICENSE({
        token: this.token,
        id: this.id,
        type: this.licenseType,
      })
      this.isLoading = false
    },
    async updateLicense() {
      this.isLoading = true
      if (this.licenseType === 'XML_APPLICATION') {
        await this.UPDATE_ID_LICENSE({
          token: this.token,
          id: this.id,
        })

        if (this.LICENSE_DATA.id_license) {
          this.licenseId = this.LICENSE_DATA.id_license
          this.licenseDate = this.LICENSE_DATA.date_expiration_license
        } else {
          this.error = 'not r-keeper platform'
        }
      } else {
        const licenseDate = await this.FETCH_END_DATA_LICENSE({
          token: this.token,
          id: this.id,
        })

        this.licenseDate = licenseDate
      }
      this.isLoading = false
    },

    toggleConfirmModal() {
      this.confirmModal = !this.confirmModal
    },
  },
}
</script>

<style lang="scss">
.vLogsLicenseRkeeper {
  position: relative;
  width: 100%;
  height: 100%;
}
.typeLicense {
  margin-bottom: 20px;
  &__title {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
  }
  &__field {
    text-align: left;
    margin-bottom: 15px;
  }
  &__button {
    margin-top: 20px;
    border-top: 1px solid #e6e7eb;
    padding-top: 40px;
  }
  .err {
    color: red;
    margin-bottom: 20px;
  }
}
</style>
