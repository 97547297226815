
import { defineComponent, PropType } from 'vue'
import { IAcquirings, IAcquiringTaxations, IAcquiringTaxAll } from '@/types/support'
import { TCurrencyType } from '@/types/acquiring'
import { IFetchRoles } from '@/types/employeeTypes'

interface IThisCurrencyTypes {
  name: string
  id: TCurrencyType
}

interface IDefaultValue {
  name: string
  id: number
}

export default defineComponent ({
  name: 'VSelect',
  props: {
    placeholder: {
      type: String as PropType<string>,
      require: true,
      default: '',
    },
    element: {
      type: String as PropType<string>,
      require: true,
      default: '',
    },
    values: {
      type: Array as PropType<IAcquirings[] | IDefaultValue[] | IAcquiringTaxations[] | IAcquiringTaxAll[] | IThisCurrencyTypes[]>,
      required: true,
      default: () => [],
    },
    data: {
      type: String || Number,
      required: true,
      default: null,
    },
    outsideClickData: {
      type: Array as PropType<EventTarget[] | null>,
      required: false,
      default: () => [],
    },
    error: {
      type: String as PropType<string>,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: false,
    },
    marginBottom: {
      type: Number as PropType<number>,
      require: false,
      default: 20,
    },
  },
  emits: ['selectValue'],
  data() {
    return {
      drop: false,
    }
  },
  watch: {
    outsideClickData() {
      const refData = this.placeholder
      if (!!this.outsideClickData && !this.outsideClickData.includes(this.$refs[refData] as EventTarget)) {
        this.drop = false
      }
    },
  },
  methods: {
    selectValue(name: string, id: TCurrencyType | number): void {
      this.$emit('selectValue', {
        name: name,
        val: id,
        id: id,
        element: this.element,
      })
      this.drop = false
    },
    toggleDrop() {
      this.drop = !this.drop
    },
    hideDrop() {
      this.drop = false
    },
  },
})
