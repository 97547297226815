<template>
  <div class="vLogsPaySystems">
    <vLightPreloader
      v-if="isLoading"
      :fixed="false"
      :transparent="true"
    />
    <div
      v-if="GET_PLATFORMS_DATA && GET_PLATFORMS_DATA.errorText"
      style="margin-bottom: 30px"
    >
      {{ GET_PLATFORMS_DATA.errorText }}
    </div>
    <div
      v-if="GET_PLATFORMS_DATA"
      class="list"
    >
      <vRadioButton
        v-for="platform in GET_PLATFORMS_DATA.platforms"
        :id="platform.id"
        :key="platform.id"
        :text="platform.name"
        :value="paySystem"
        name="platform"
        @changeData="changePay"
      />
    </div>
    <vButton
      :fix-width="true"
      :disabled="!paySystem"
      @handleClick="toggleConfirmModal"
    >
      Сохранить
    </vButton>
    <VModalConfirmChanges
      v-if="confirmModal"
      @closeModal="toggleConfirmModal"
      @confirmChanges="savePayPlatform"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vRadioButton from '@/components/v-radio-button'
import vButton from '@/components/v-button'
import vLightPreloader from '@/components/v-light-preloader'
import VModalConfirmChanges from '@/components/modals/v-modal-confirm-changes'
export default {
  name: 'VLogsPaySystems',
  components: {
    vRadioButton,
    vButton,
    vLightPreloader,
    VModalConfirmChanges,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: true,
      paySystem: null,
      confirmModal: false,
    }
  },
  computed: {
    ...mapGetters('support', ['ERROR', 'RKEEPER_DATA', 'GET_PLATFORMS_DATA']),
  },
  async mounted() {
    await this.FETCH_PLATFORM({ token: this.token, id: this.id })
    this.paySystem = this.GET_PLATFORMS_DATA.data
      ? this.GET_PLATFORMS_DATA.data.id
      : null
    this.isLoading = false
  },
  methods: {
    ...mapActions('support', [
      'FETCH_DATA_RKEEPER',
      'SAVE_DATA_RKEEPER',
      'FETCH_PLATFORM',
      'SAVE_PLATFORM',
    ]),
    ...mapActions('restaurant', ['CHANGE_PLATFORM_REST']),
    changePay(val) {
      this.paySystem = val
    },
    async savePayPlatform() {
      this.toggleConfirmModal()
      this.isLoading = true
      const response = await this.SAVE_PLATFORM({
        token: this.token,
        id: this.id,
        platform_id: this.paySystem,
      })
      this.CHANGE_PLATFORM_REST({
        id: this.id,
        platformsId: this.paySystem,
        externalId: response.success.external_id,
      })
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },

    toggleConfirmModal() {
      this.confirmModal = !this.confirmModal
    },
  },
}
</script>

<style lang="scss" scoped>
.vLogsPaySystems {
  position: relative;
  width: 100%;
  height: 100%;
}
.list {
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 15px;
  }
}
</style>
