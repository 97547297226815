<template>
  <div class="vLogsForm">
    <vLightPreloader
      v-if="isLoading"
      :fixed="false"
      :transparent="true"
    />
    <div v-if="GET_PLATFORMS_DATA && GET_PLATFORMS_DATA.errorText">
      {{ GET_PLATFORMS_DATA.errorText }}
    </div>
    <div v-if="GET_PLATFORMS_DATA && GET_PLATFORMS_DATA.data">
      <div class="vLogsForm__header">
        <h3>
          <img
            v-if="GET_PLATFORMS_DATA.data.name === 'iiko'"
            src="@/assets/images/logo-iiko.png"
            width="100"
          >
          <img
            v-if="GET_PLATFORMS_DATA.data.name === 'r-keeper'"
            src="@/assets/images/logo-r_keeper.png"
            width="100"
          >
        </h3>
        <div class="checkout__version">
          <div
            class="checkout__version-info"
            :class="{ err: checkoutError }"
          >
            <img
              v-if="isLoadingVersion"
              class="checkout__version-preloader"
              src="~@/assets/images/light-preloader.gif"
              alt="loading..."
            >
            <div v-else-if="!isLoadingVersion && checkoutError">
              Касса недоступна
            </div>
            <div v-else>
              {{ checkoutVersion ? checkoutVersion : 'Версия не известна' }}
            </div>
          </div>
          <div
            v-if="!isLoadingVersion && moduleVersion"
            class="checkout__version-info"
          >
            {{ moduleVersion }}
          </div>
          <div
            class="checkout__version-request"
            @click="onCheckoutVersion"
          >
            Запросить
          </div>
        </div>
      </div>

      <!-- r-keeper -->
      <Form
        v-if="
          GET_PLATFORMS_DATA.data &&
            GET_PLATFORMS_DATA.data.name === 'r-keeper'
        "
        v-slot="{ errors }"
        class="section-profile__form"
        @submit="toggleConfirmModal"
      >
        <vInput
          v-for="item in rkeeper"
          :id="item.name"
          :key="item.id"
          v-model="item.value"
          :type="item.type"
          :value="item.value"
          :required="true"
          :rule="item.type === 'password' ? 'min:6' : 'min:1'"
          :placeholder="item.placeholder"
          :disabled="item.disabled"
        />

        <div class="activity_establishments">
          <label class="checkbox__conditions max-width_availability">
            <input
              v-model="checkingAvailability"
              type="checkbox"
              checked
              class="checkbox__conditions-check"
            >
            <p class="checkbox__conditions-text">
              Проверка на доступность кассы
            </p>
          </label>

          <label class="checkbox__conditions max-width_plug">
            <input
              v-model="plugCheckbox"
              type="checkbox"
              checked
              class="checkbox__conditions-check"
            >
            <p class="checkbox__conditions-text">Заглушка</p>
          </label>
        </div>

        <vButton
          type="submit"
          :disabled="Object.keys(errors).length > 0"
        >
          Сохранить
        </vButton>
        <div v-if="ERROR">
          Ошибка сохранения данных
        </div>
      </Form>

      <!-- iiko -->
      <Form
        v-if="
          GET_PLATFORMS_DATA.data && GET_PLATFORMS_DATA.data.name === 'iiko'
        "
        v-slot="{ errors }"
        class="section-profile__form"
        @submit="toggleConfirmModal"
      >
        <vInput
          v-for="item in iiko"
          :id="item.name"
          :key="item.id"
          v-model="item.value"
          :type="item.type"
          :value="item.value"
          :required="true"
          :rule="item.type === 'password' ? 'min:6' : 'min:1'"
          :placeholder="item.placeholder"
          :disabled="item.disabled"
        />

        <div class="activity_establishments">
          <label class="checkbox__conditions max-width_availability">
            <input
              v-model="checkingAvailability"
              type="checkbox"
              checked
              class="checkbox__conditions-check"
            >
            <p class="checkbox__conditions-text">
              Проверка на доступность кассы
            </p>
          </label>

          <label class="checkbox__conditions max-width_plug">
            <input
              v-model="plugCheckbox"
              type="checkbox"
              checked
              class="checkbox__conditions-check"
            >
            <p class="checkbox__conditions-text">Заглушка</p>
          </label>
        </div>

        <vButton
          type="submit"
          :disabled="Object.keys(errors).length > 0"
        >
          Сохранить
        </vButton>
        <div v-if="ERROR">
          Ошибка сохранения данных
        </div>
      </Form>
    </div>
    <VModalConfirmChanges
      v-if="confirmModal"
      @closeModal="toggleConfirmModal"
      @confirmChanges="checkForm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Form } from 'vee-validate'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import vLightPreloader from '@/components/v-light-preloader'
import VModalConfirmChanges from '@/components/modals/v-modal-confirm-changes'
export default {
  name: 'VLogsForm',
  components: {
    vLightPreloader,
    Form,
    vInput,
    vButton,
    VModalConfirmChanges,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      isLoading: true,
      isLoadingVersion: false,
      checkoutError: false,
      checkingAvailability: false,
      plugCheckbox: false,
      iiko: [
        { id: 1, name: 'id', type: 'text', placeholder: 'ID', disabled: true },
        { id: 3, name: 'ip', type: 'text', placeholder: 'IP' },
        { id: 4, name: 'port', type: 'text', placeholder: 'Порт' },
        { id: 5, name: 'login', type: 'text', placeholder: 'Логин' },
        { id: 6, name: 'password', type: 'password', placeholder: 'Пароль' },
        {
          id: 7,
          name: 'shopId',
          type: 'text',
          placeholder: 'ID заведения',
          disabled: true,
        },
      ],
      rkeeper: [
        { id: 1, name: 'id', type: 'text', placeholder: 'ID', disabled: true },
        {
          id: 6,
          name: 'login',
          type: 'text',
          placeholder: 'Логин пользователя rkeeper',
        },
        {
          id: 2,
          name: 'password',
          type: 'text',
          placeholder: 'Пароль пользователя rkeeper',
        },
        {
          id: 3,
          name: 'login_license',
          type: 'text',
          placeholder: 'Логин для доступа лицензирования',
        },
        {
          id: 4,
          name: 'password_license',
          type: 'text',
          placeholder: 'Пароль для доступа лицензирования',
        },
        {
          id: 5,
          name: 'token_license',
          type: 'text',
          placeholder: 'Токен лицензии',
          disabled: true,
        },
        { id: 7, name: 'url', type: 'text', placeholder: 'IP адрес' },
        { id: 8, name: 'restCode', type: 'text', placeholder: 'Код ресторана' },
        {
          id: 9,
          name: 'productGUID',
          type: 'text',
          placeholder: 'ID лицензии',
        },
        {
          id: 10,
          name: 'license_instance',
          type: 'text',
          placeholder: 'Экземпляр лицензии',
        },
        {
          id: 11,
          name: 'id_currency',
          type: 'text',
          placeholder: 'Идентификатор валюты',
        },
        {
          id: 12,
          name: 'first_request',
          type: 'text',
          placeholder: 'Первый запрос',
        },
        {
          id: 13,
          name: 'id_manager',
          type: 'text',
          placeholder: 'Code менеджера',
        },
        {
          id: 14,
          name: 'id_station',
          type: 'text',
          placeholder: 'Code станции',
        },
        {
          id: 15,
          name: 'id_reason',
          type: 'text',
          placeholder: 'ID причины внесения предоплаты',
        },
        { id: 16, name: 'acc_number', type: 'text', placeholder: 'Acc' },
      ],
      confirmModal: false,
      checkoutVersion: null,
      moduleVersion: null,
    }
  },
  computed: {
    ...mapGetters('support', [
      'ERROR',
      'GET_PLATFORMS_DATA',
      'RKEEPER_DATA',
      'GET_ACTIVITY_STATUS_DATA',
      'GET_CHECKOUT_VERSION',
    ]),
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async mounted() {
    await this.SHOP_ACTIVE({token: this.token, id: this.id})
    if (this.GET_ACTIVITY_STATUS_DATA.check_active === 1) {
      this.checkingAvailability = true
    } else {
      this.checkingAvailability = false
    }

    if (this.GET_ACTIVITY_STATUS_DATA.active === 1) {
      this.plugCheckbox = false
    } else {
      this.plugCheckbox = true
    }

    await this.FETCH_PLATFORM({token: this.token, id: this.id})
    if (
      this.GET_PLATFORMS_DATA.data &&
      this.GET_PLATFORMS_DATA.data.name === 'r-keeper'
    ) {
      await this.FETCH_DATA_RKEEPER({token: this.token, id: this.id})
      Object.keys(this.rkeeper).forEach((i) => {
        const field = this.RKEEPER_DATA[this.rkeeper[i]['name']]
          ? this.RKEEPER_DATA[this.rkeeper[i]['name']]
          : ''
        this.rkeeper[i].value = field.toString()
      })
    }
    if (
      this.GET_PLATFORMS_DATA.data &&
      this.GET_PLATFORMS_DATA.data.name === 'iiko'
    ) {
      await this.FETCH_DATA_IIKO({token: this.token, id: this.id})

      Object.keys(this.iiko).forEach((i) => {
        const field = this.RKEEPER_DATA[this.iiko[i]['name']]
          ? this.RKEEPER_DATA[this.iiko[i]['name']]
          : ''
        this.iiko[i].value = field.toString()
      })
    }

    const systemVersion = this.RKEEPER_DATA.accountingSystemVersion
    const moduleVersion = this.RKEEPER_DATA.moduleVersion

    this.checkoutVersion = systemVersion && systemVersion !== '0.0.0.0' ? systemVersion : null
    this.moduleVersion = moduleVersion && moduleVersion !== '0.0.0.0' ? moduleVersion : null

    this.isLoading = false
  },
  methods: {
    ...mapActions('support', [
      'FETCH_PLATFORM',
      'FETCH_DATA_RKEEPER',
      'SAVE_DATA_RKEEPER',
      'FETCH_DATA_IIKO',
      'SAVE_DATA_IIKO',
      'SHOP_ACTIVE',
      'SHOP_ACTIVE_CONSERVATION',
      'FETCH_CHECKOUT_VERSION',
    ]),

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    checkForm() {
      this.toggleConfirmModal()
      this.isLoading = true
      const req = {
        shops_id: this.id.toString(),
      }

      if (this.GET_PLATFORMS_DATA.data.name === 'r-keeper') {
        this.rkeeper.forEach((i) => {
          req[i.name] = i.value
        })
        this.SAVE_DATA_RKEEPER({ data: req, token: this.token })
      }

      if (this.GET_PLATFORMS_DATA.data.name === 'iiko') {
        this.iiko.forEach((i) => {
          req[i.name] = i.value
        })
        this.SAVE_DATA_IIKO({ data: req, token: this.token })
      }

      this.checkboxConditions()

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    checkboxConditions() {
      if (this.checkingAvailability) {
        this.check_active = 1
      } else {
        this.check_active = 0
      }
      if (this.plugCheckbox) {
        this.active = 0
      } else {
        this.active = 1
      }
      const req = {
        id: this.id.toString(),
        active: this.active,
        check_active: this.check_active,
      }
      this.SHOP_ACTIVE_CONSERVATION({ data: req, token: this.token })
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    toggleConfirmModal() {
      this.confirmModal = !this.confirmModal
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async onCheckoutVersion () {
      this.checkoutError = false
      this.isLoadingVersion = true
      await this.FETCH_CHECKOUT_VERSION({
        id: this.id,
        token: this.token
      })

      const systemVersion = this.GET_CHECKOUT_VERSION.accountingSystemVersion
      const moduleVersion = this.GET_CHECKOUT_VERSION.moduleVersion

      this.checkoutVersion = systemVersion && systemVersion !== '0.0.0.0' ? systemVersion : null
      this.moduleVersion = moduleVersion && moduleVersion !== '0.0.0.0' ? moduleVersion : null

      if (!systemVersion && !moduleVersion) {
        this.checkoutError = true
      }

      this.isLoadingVersion = false
    }
  },
}
</script>

<style lang="scss">
.vLogsForm {
  position: relative;
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  .checkout__version {
    display: flex;
    align-items: flex-end;
    margin-left: 32px;
    &-info {
      width: 172px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid #E6E7EB;
      color: #676E86;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      &:nth-child(2) {
        margin-left: 16px;
      }
      &.err {
        background-color: #FFF6F6;
        border: 1px solid #EC4E4E;
        color: #EC4E4E;
      }
    }
    &-preloader {
      width: 40px;
      height: 40px;
    }
    &-request {
      cursor: pointer;
      width: 118px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      border-radius: 12px;
      border: 1px solid #6764FF;
      color: #6764FF;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
  }

  .checkbox__conditions {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #80869a;

    &-check {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked + .checkbox__conditions-text::before {
        background-image: url('~@/assets/images/paid_check.d789c5ba.svg');
      }
    }

    &-text {
      display: inline-flex;
      align-items: center;
      user-select: none;

      &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1.5px solid #dcdee3;
        border-radius: 0.4em;
        margin-right: 17px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px;
      }

      & a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #80869a;
      }
    }
  }
}
.max-width_availability {
  max-width: 215px;
}
.max-width_plug {
  max-width: 90px;
}
</style>
