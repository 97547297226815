import { defineStore } from 'pinia'
import { ref } from 'vue'
import loyaltyService from '@/services/loyaltyService'
import { AxiosError } from 'axios'
import { IGetLoyaltySystem } from '@/types/loyaltyTypes'

export const useLoyaltyStore = defineStore('loyaltyStore', () => {
  // state
  const loyaltySystem = ref<IGetLoyaltySystem | null>(null)

  // Получить тип системы лояльности
  const fetchLoyaltySystem = async (id: number, token: string) => {
    try {
      const response: IGetLoyaltySystem = await loyaltyService.getLoyaltySystem(id, token)
      loyaltySystem.value = response
      return response
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('fetchLoyaltySystemERROR', error.response?.data);
      console.log('====================================');
    }
  }

  return {
    loyaltySystem,
    fetchLoyaltySystem
  }
})
