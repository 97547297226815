<template>
  <div class="vLogsTips">
    <vLightPreloader
      v-if="isLoading"
      :fixed="false"
      :transparent="true"
    />
    <div class="vLogsTips__title">
      Оплата чаевых
    </div>
    <div class="vLogsTips__buttons">
      <vRadioButton
        id="TAPPER"
        text="Tapper"
        :value="tipsType"
        name="tipsType"
        @changeData="changetipsType"
      />
      <vRadioButton
        id="LINK"
        text="Чаевые по ссылке"
        :value="tipsType"
        name="tipsType"
        @changeData="changetipsType"
      />
      <vRadioButton
        id="DISABLED"
        text="Выкл."
        :value="tipsType"
        name="tipsType"
        @changeData="changetipsType"
      />
    </div>
    <div class="vLogsTips__button">
      <vButton
        :disabled="!tipsType"
        @click="save"
      >
        Сохранить
      </vButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vRadioButton from '@/components/v-radio-button'
import vLightPreloader from '@/components/v-light-preloader'
import vButton from '@/components/v-button'
export default {
  name: 'VLogsTips',
  components: {
    vRadioButton,
    vLightPreloader,
    vButton,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
    selectedRest: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  emits: ['chageDataSelectedRest'],
  data() {
    return {
      tipsType: null,
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters('restaurant', ['GET_SELECT_REST']),
  },
  async mounted() {
    this.tipsType = this.selectedRest.tips_type
    setTimeout(() => {
      this.isLoading = false
    }, 500)
  },
  methods: {
    ...mapActions('tips', ['CHANGE_TIPS_TYPE']),
    ...mapActions('restaurant', ['FETCH_RESTAURANTS', 'CHANGE_TIPS']),

    changetipsType(val) {
      this.tipsType = val
    },
    async save() {
      this.isLoading = true
      await this.CHANGE_TIPS_TYPE({
        token: this.token,
        id: this.id,
        type: this.tipsType,
      })
      await this.FETCH_RESTAURANTS({ token: this.token, search: '' })
      this.$emit('chageDataSelectedRest', {
        ...this.selectedRest,
        tips_type: this.tipsType,
      })
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.vLogsTips {
  position: relative;
  min-height: calc(100vh - 375px);
  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    * {
      margin-bottom: 15px;
    }
  }
  &__button {
    max-width: 500px;
  }
}
</style>
