export const convertUtc = (period: Date[]): string => {
  const periodFrom = period[0]
    .toLocaleString('ru-RU', { dateStyle: 'short' })
    .split('-')
    .reverse()
    .join('.')

  const periodTo = period[1]
    .toLocaleString('ru-RU', { dateStyle: 'short' })
    .split('-')
    .reverse()
    .join('.')

  return periodFrom === periodTo ? periodFrom : periodFrom + ' - ' + periodTo
}
