import { defineStore } from 'pinia'
import { ref } from 'vue'
import FeeTypeService from '@/services/feeTypeService'
import { AxiosError } from 'axios'
import { TFeeType } from '@/types/feeTypes'

export const useFeeType = defineStore('feeStore', () => {
  //state
  const feeType = ref<boolean>(true)

  //Получить тип чаевых
  const fetchFeeType = async (id: number, token: string): Promise<void> => {
    try {
      const response = await FeeTypeService.fetch(id, token)
      feeType.value = response.fee_type === 'WITHOUT_FEE' ? false : true
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('fetchFeeTypeError', error.response);
      console.log('====================================');
    }
  }

  //Поменять тип чаевых
  const changeFeeType = async (id: number, token: string, fee_type: TFeeType): Promise<void> => {
    try {
      const response = await FeeTypeService.changeType(id, token, fee_type)
      feeType.value = response.success === true ? !feeType.value : feeType.value
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('changeFeeTypeError', error.response);
      console.log('====================================');
    }
  }

  return {
    feeType,
    fetchFeeType,
    changeFeeType
  }
})

