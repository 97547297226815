import { $authHost } from './api'
import { AxiosResponse, AxiosError } from 'axios'
import { IScheduleResponse, IPutScheduleResponse, ISchedule } from '@/types/scheduleTypes';

interface IPayload {
  id: number
  token: string
}

interface ICity {
  name: string
  geo_lat: string
  geo_lon: string
}

interface IPutPayload {
  id: number
  token: string
  city: ICity
  schedule: ISchedule
}

class ScheduleService {
  async getSchedule(payload: IPayload) {
    const { id, token } = payload

    try {
      const { data }: AxiosResponse<IScheduleResponse> = await $authHost.get(`/shop/${id}/city`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        }
      })
      return data.result
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('getScheduleServiceError', error.response);
      console.log('====================================');
    }
  }

  async putSchedule(payload: IPutPayload) {
    const { id, token, city, schedule } = payload

    try {
      const { data }: AxiosResponse<IPutScheduleResponse> = await $authHost.put(`/shop/${id}/city`,
      {
        city: city,
        schedule: schedule
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        }
      })
      return data
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('putScheduleServiceError', error.response);
      console.log('====================================');
    }
  }
}

export default new ScheduleService
