
import { defineComponent, PropType } from 'vue'
import vCloseButton from '@/components/v-close-button.vue'
import vButton from '@/components/v-button.vue'
export default defineComponent ({
  name: 'VModalConfirmationChangesDetails',
  components: {
    vCloseButton,
    vButton,
  },
  props: {
    summ: {
      type: Number as PropType<number>,
      require: true,
      default: 0,
    },
    token: {
      type: String as PropType<string>,
      required: true,
      default: '',
    },
  },
  emits: ['onModalChangingDetails', 'savingDetailsInstitution'],
  computed: {},
  async mounted() {},
  methods: {
    onModalChangingDetails(): void {
      this.$emit('onModalChangingDetails')
    },

    savingDetailsInstitution(): void {
      this.$emit('savingDetailsInstitution')
      this.onModalChangingDetails()
    },
  },
})
