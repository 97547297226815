
import { defineComponent, PropType } from 'vue'
import vCloseButton from '@/components/v-close-button.vue'
export default defineComponent ({
  name: 'VModal',
  components: {
    vCloseButton,
  },
  props: {
    styleBottom: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: false,
    },
    closeBtn: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: true,
    }
  },
  emits: ['closeModal'],
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
  },
})
