<template>
  <div class="vLogsStatistic">
    <vLightPreloader
      v-show="isLoading"
      :fixed="false"
      :transparent="true"
    />
    <div class="vLogsStatistic__container">
      <div class="vLogsStatistic__calendar">
        <div class="vLogsStatistic__calendar__period">
          <div
            class="vLogsStatistic__calendar__period-label"
            :class="{ error: selectedNumberDays > 30 }"
          >
            <p
              class="vLogsStatistic__calendar__period-label__text"
              :class="{ active: filterDate }"
            >
              {{ filterDate ? convertUTC : 'Выберите дату или диапазон дат' }}
            </p>
            <span
              v-if="filterDate"
              class="vLogsStatistic__calendar__period-label__date"
            >Дата</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="vLogsStatistic__calendar__period-label__svg"
            >
              <path
                d="M3 9.5H21M7.5 3V6V3ZM16.5 3V6V3Z"
                stroke="#80869A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 4.5H6C4.34315 4.5 3 5.84315 3 7.5V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V7.5C21 5.84315 19.6569 4.5 18 4.5Z"
                stroke="#80869A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <vDatePicker
            v-model="filterDate"
          />
        </div>
        <div
          v-if="selectedNumberDays !== '' && selectedNumberDays > 30"
          class="vLogsStatistic__calendar__text-error"
        >
          Выберите период, который будет менее 31 дня
        </div>
        <div
          v-if="filterDate"
          class="vLogsStatistic__calendar__reset"
        >
          <vButtonRed
            :fix-width="true"
            @click="resetFilterDate"
          >
            Сбросить
          </vButtonRed>
        </div>
      </div>

      <vButton
        :fix-width="true"
        :disabled="!filterDate || selectedNumberDays > 30"
        @click="uploadTable"
      >
        Выгрузить таблицу
      </vButton>

      <vButton
        :fix-width="true"
        @click="waitersBalances"
      >
        Балансы официантов
      </vButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vLightPreloader from '@/components/v-light-preloader'
import vButton from '@/components/v-button'
import vButtonRed from '@/components/v-button-red'
import vDatePicker from '@/components/v-date-picker.vue'
import { filterPeriod } from '@/utils/filterDate'
import { convertUtc } from '@/utils/convertUtc'
export default {
  name: 'VLogsStatistic',
  components: {
    vDatePicker,
    vLightPreloader,
    vButton,
    vButtonRed,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      filterDate: null,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    convertUTC() {
      return this.filterDate ? convertUtc(this.filterDate) : ''
    },

    selectedNumberDays() {
      if (this.filterDate) {
        const dayOne = new Date(this.filterDate[0])
        const dayTwo = new Date(this.filterDate[1])
        const finalDay = Math.ceil(
          Math.abs(dayTwo.getTime() - dayOne.getTime()) / (1000 * 3600 * 24)
        )
        return finalDay
      }
      return ''
    },
  },
  mounted() {
    const body = document.querySelector('body')
    body.classList.add('calendarPage')
  },

  unmounted() {
    const body = document.querySelector('body')
    body.classList.remove('calendarPage')
  },
  methods: {
    ...mapActions('statistic', [
      'EXPORT_STATISTIC',
      'EXPORT_STATISTIC_PERIOD',
      'EXPORT_BALANCE',
    ]),


    async uploadTable() {
      this.isLoading = true

      const dates = filterPeriod(this.filterDate)

      const periodRequest = {
        token: this.GET_AUTHENTIFICATED,
        from: dates.startDate,
        before: dates.endDate,
      }

      const dateRequest = {
        token: this.GET_AUTHENTIFICATED,
        date: dates.startDate,
      }

      if (dates.startDate === dates.endDate) {
        await this.EXPORT_STATISTIC(dateRequest)
      } else {
        await this.EXPORT_STATISTIC_PERIOD(periodRequest)
      }
      this.filterDate = null
      this.isLoading = false
    },

    resetFilterDate() {
      this.filterDate = null
    },
    async waitersBalances() {
      const d3 = new Date(),
        month3 =
          d3.getMonth() + 1 < 10
            ? '0' + (d3.getMonth() + 1)
            : d3.getMonth() + 1,
        day3 = d3.getDate() < 10 ? '0' + d3.getDate() : d3.getDate(),
        year3 = d3.getFullYear()
      const todaDate = `${year3}-${month3}-${day3}`

      await this.EXPORT_BALANCE({
        token: this.GET_AUTHENTIFICATED,
        date: todaDate,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.vLogsStatistic {
  position: relative;
  width: 100%;
  height: 100%;
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__calendar {
    margin-bottom: 25px;
    &__period {
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      @media (max-width: 769px) {
        margin-top: 16px;
      }
      @media (max-width: 370px) {
        margin-top: 0;
      }
      &-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #80869a;
        cursor: pointer;
        padding: 0 12px;
        border: 1px solid #e6e7eb;
        border-radius: 12px;
        //margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 52px;
        width: 313px;
        &__text {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #80869a;
          &.active {
            color: #010d35;
            padding-top: 22px;
          }
        }
        &__date {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #80869a;
          position: absolute;
          top: 8px;
        }
        &__svg {
          //margin-left: 4px;
          stroke: #80869a;
        }
      }
    }
    &__text-error {
      color: #ec4e4e;
      font-size: 15px;
      margin-top: 16px;
    }
    &__reset {
      margin-top: 16px;
    }
  }
}
</style>
