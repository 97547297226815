
import { defineComponent } from 'vue'
import VButton from '@/components/v-button.vue'
import vCloseButton from '@/components/v-close-button.vue'
export default defineComponent ({
  name: 'VModalConfirmChanges',
  components: {
    VButton,
    vCloseButton
  },
  emits: ['closeModal', 'confirmChanges'],
})
