
import { defineComponent, PropType } from 'vue'
export default defineComponent ({
  name: 'VIconDownload',

  props: {
    color: {
      type: String as PropType<string>,
      required: false,
      default: '#010d35'
    }
  }
})
