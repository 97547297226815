
import { defineComponent, PropType } from 'vue'
import { mapActions, mapGetters } from "vuex"
import convertPhoneToFull from "@/utils/convertPhoneToFull"
import convertPhoneFormat from "@/utils/convertPhoneFormat"
import { IAcquiringTaxAll, IAcquiringTaxations, IAcquiringSupport, ICurrencyTypes, IAcquirings } from '@/types/support'
import { ISaveAcquiringPayload, TCurrencyType } from '@/types/acquiring'

import VModalConfirmationChangesDetails from '@/components/modals/v-modal-confirmation-changes-details.vue'
import VModalConfirmChanges from '@/components/modals/v-modal-confirm-changes.vue'
import VModalCloseEnitity from '@/components/modals/v-modal-close-enitity.vue'
import VIconMinusInCircle from '@/assets/images/icons/v-icon-minus-in-circle.vue'
import vLightPreloader from '@/components/v-light-preloader.vue'
import vButton from '@/components/v-button.vue'
import vInput from '@/components/v-input.vue'
import vSelect from '@/components/v-select.vue'

type TAcquiringCode = 'BEST2PAY_BARREL' | 'Best2pay' | 'CloudPayments'

interface ISelectValueData {
  name: string
  val: number
  id: number
  element: TAcquiringCode
}

interface IThisCurrencyTypes {
  name: string
  id: TCurrencyType
}

export default defineComponent ({
  name: 'VLogsTwoPaySystems',
  components: {
    VModalConfirmationChangesDetails,
    VModalConfirmChanges,
    VModalCloseEnitity,
    VIconMinusInCircle,
    vLightPreloader,
    vButton,
    vInput,
    vSelect
  },
  props: {
    token: {
      type: String as PropType<string>,
      required: true,
      default: '',
    },
    id: {
      type: Number as PropType<number>,
      required: true,
      default: 0,
    }
  },
  emits: ['onTwoLegalEntity'],
  data() {
    return {
      modalCloud: false,
      modalCloseEnitity: false,
      platforms: [] as IAcquirings[],
      selectPlatform: {
        id: 0,
        name: '',
      },
      taxAll: [] as IAcquiringTaxAll[],
      taxations: [] as IAcquiringTaxations[],
      isLoading: true as boolean,
      requisitesDisabled: true,
      modalChangingDetails: false,
      hasChanges: false,
      objectInput: {
        phone: '',
        inn: '',
        nameOrganization: '',
      },
      Best2pay: {
        classId: '',
        groupId: '',
        salePoint: '',
        accNumber: '',
        taxations: {
          name: '',
          val: '',
        },
        nds: {
          name: '',
          val: '',
        },
      },
      BEST2PAY_BARREL: {
        classId: '',
        groupId: '',
        salePoint: '',
        accNumber: '',
        taxations: {
          name: '',
          val: '',
        },
        nds: {
          name: '',
          val: '',
        },
      },
      CloudPayments: {
        // public_id: '',
        classId: '',
        groupId: '',
        salePoint: '',
        accNumber: '',
        taxations: {
          name: '',
          val: '',
        },
        nds: {
          name: '',
          val: '',
        },
      },
      currencyTypes: [] as IThisCurrencyTypes[],
      currencyType: {
        name: '',
        id: '',
      },
      outsideClickData: null as EventTarget[] | null,
      confirmModal: false,
    }
  },
  computed: {
    ...mapGetters('acquiring', [
      'GET_DETAILS',
      'GET_ACQUIRIG'
    ]),
    ...mapGetters('support', [
      'GET_ACQUIRIG_SUPPORT',
      'GET_PLATFORMS_DATA'
    ]),

    currencyTypeName(): string {
      return this.currencyType.name ? this.currencyType.name : ''
    },

    platformName(): string {
      return this.selectPlatform.name ? this.selectPlatform.name : ''
    },
    // disabledCloudButton() {
    //   if (this.CloudPayments.public_id) {
    //     return !this.CloudPayments.public_id.length && !this.hasChanges
    //   } else {
    //     return true
    //   }
    // },

    // eslint-disable-next-line vue/return-in-computed-property
    disButtonBarrel() {
      if (this.GET_PLATFORMS_DATA?.data?.name === 'r-keeper') {
        if (
          !this.BEST2PAY_BARREL.accNumber ||
          !this.BEST2PAY_BARREL.accNumber.length ||
          !this.BEST2PAY_BARREL.classId ||
          !this.BEST2PAY_BARREL.classId.length ||
          !this.BEST2PAY_BARREL.groupId ||
          !this.BEST2PAY_BARREL.groupId.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      } else if (this.GET_PLATFORMS_DATA?.data?.name === 'iiko') {
        if (
          !this.BEST2PAY_BARREL.accNumber ||
          !this.BEST2PAY_BARREL.accNumber.length ||
          !this.BEST2PAY_BARREL.salePoint ||
          !this.BEST2PAY_BARREL.salePoint.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      } else {
        if (
          !this.BEST2PAY_BARREL.accNumber ||
          !this.BEST2PAY_BARREL.accNumber.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      }
    },

    // eslint-disable-next-line vue/return-in-computed-property
    disButtonBest2pay() {
      if (this.GET_PLATFORMS_DATA?.data?.name === 'r-keeper') {
        if (
          !this.Best2pay.accNumber ||
          !this.Best2pay.accNumber.length ||
          !this.Best2pay.classId ||
          !this.Best2pay.classId.length ||
          !this.Best2pay.groupId ||
          !this.Best2pay.groupId.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      } else if (this.GET_PLATFORMS_DATA?.data?.name === 'iiko') {
        if (
          !this.Best2pay.accNumber ||
          !this.Best2pay.accNumber.length ||
          !this.Best2pay.salePoint ||
          !this.Best2pay.salePoint.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      } else {
        if (
          !this.Best2pay.accNumber ||
          !this.Best2pay.accNumber.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      }
    },
  },
  watch: {
    objectInput: {
      deep: true,
      handler() {
        this.requisitesDisabled = false
      },
    },

    selectPlatform: {
      deep: true,
      handler() {
        this.taxAll = this.GET_ACQUIRIG_SUPPORT.tax_all.filter(
          (i: IAcquiringTaxAll) => i.acquiring_id === this.selectPlatform.id
        )

        this.taxations = this.GET_ACQUIRIG_SUPPORT.taxations.filter(
          (i: IAcquiringTaxations) => i.acquiring_id === this.selectPlatform.id
        )
      },
    },

    disBarrel() {
      if (
        !this.BEST2PAY_BARREL.accNumber ||
        !this.BEST2PAY_BARREL.accNumber.length ||
        !this.hasChanges
      ) {
        return true
      } else {
        return false
      }
    }
  },
  async mounted() {
    this.isLoading = true
    await this.FETCH_PLATFORM({token: this.token, id: this.id})
    await this.gettingDetails()
    try {
      this.isLoading = true
      await this.fetchAcquiringData()
    } catch (e) {
      console.error('error fetchAcquiringData', e)
    } finally {
      this.isLoading = false
    }

    if (this.GET_DETAILS[2]) {
      this.objectInput.nameOrganization = this.GET_DETAILS[2]?.name_organization
      this.objectInput.inn = this.GET_DETAILS[2]?.inn
      this.objectInput.phone = this.filterPhone(this.GET_DETAILS[2]?.phone)
      setTimeout(() => {
        this.requisitesDisabled = true
      }, 100)
    }
  },
  unmounted() {
    this.hasChanges = false
  },
  methods: {
    ...mapActions('acquiring', [
      'FETCH_ACQUIRIG',
      'GETTING_DETAILS',
      'SAVING_DETAILS_INSTITUTION',
      'SAVE_ACQUIRIG'
    ]),
    ...mapActions('support', [
      'FETCH_ACQUIRIG_SUPPORT',
      'FETCH_PLATFORM',
      'SETUP_ACQUIRIG_SUPPORT',
    ]),
    ...mapActions('shops', ['PUT_TWO_ENTITIES']),

    onModalChangingDetails(): void {
      this.modalChangingDetails = !this.modalChangingDetails
    },

    async gettingDetails(): Promise<void> {
      const data: {id: number, token: string} = {
        token: this.token,
        id: this.id
      }
      await this.GETTING_DETAILS(data)
    },

    filterPhone(phone: string): string {
      if (phone) {
        let ph = phone.replace(/\s/g, '')
        ph = ph.slice(1)
        return convertPhoneToFull(ph)
      }
      return ''
    },

    async savingDetailsInstitution(): Promise<void> {
      this.isLoading = true
      this.requisitesDisabled = true
      const res = {
        id: this.id,
        entity: 2,
        name_organization: this.objectInput.nameOrganization,
        inn: this.objectInput.inn,
        phone: convertPhoneFormat(this.objectInput.phone),
        token: this.token,
      }
      await this.SAVING_DETAILS_INSTITUTION(res)
      this.isLoading = false
    },

    async fetchAcquiringData() {
      const response: IAcquiringSupport = await this.FETCH_ACQUIRIG_SUPPORT({
        id: this.id,
        token: this.token,
      })
      this.platforms = this.GET_ACQUIRIG_SUPPORT
        ? this.GET_ACQUIRIG_SUPPORT.acquirings
        : []
      if (response) {
        await this.FETCH_ACQUIRIG({
          id: this.id,
          token: this.token,
        })

        this.selectPlatform.name = this.GET_ACQUIRIG[2]?.acquiring_code
        this.selectPlatform.id = this.GET_ACQUIRIG[2]?.acquiring_id

        this[this.GET_ACQUIRIG[2]?.acquiring_code as TAcquiringCode] = {
          classId: '',
          groupId: '',
          salePoint: '',
          accNumber: '',
          nds: {
            name: '',
            val: '',
          },
          taxations: {
            name: '',
            val: '',
          },
        }

        this.taxAll = this.GET_ACQUIRIG_SUPPORT.tax_all.filter(
          (i: IAcquiringTaxAll) => i.acquiring_id === this.GET_ACQUIRIG[2].acquiring_id
        )
        this.taxations = this.GET_ACQUIRIG_SUPPORT.taxations.filter(
          (i: IAcquiringTaxations) => i.acquiring_id === this.GET_ACQUIRIG[2].acquiring_id
        )

        if (
          this.GET_ACQUIRIG[2]?.acquiring_code === 'Best2pay' ||
          this.GET_ACQUIRIG[2]?.acquiring_code === 'BEST2PAY_BARREL' ||
          this.GET_ACQUIRIG[2]?.acquiring_code === 'CloudPayments'
        ) {
          if (this.GET_ACQUIRIG[2]?.acc_number) {
            this[this.GET_ACQUIRIG[2]?.acquiring_code as TAcquiringCode]['accNumber'] =
              this.GET_ACQUIRIG[2]?.acc_number
          }
          if (this.GET_ACQUIRIG[2]?.cash_register_entity_settings.class_id) {
            this[this.GET_ACQUIRIG[2]?.acquiring_code as TAcquiringCode]['classId'] =
              this.GET_ACQUIRIG[2]?.cash_register_entity_settings.class_id
          }
          if (this.GET_ACQUIRIG[2]?.cash_register_entity_settings.group_id) {
            this[this.GET_ACQUIRIG[2]?.acquiring_code as TAcquiringCode]['groupId'] =
              this.GET_ACQUIRIG[2]?.cash_register_entity_settings.group_id
          }
          if (this.GET_ACQUIRIG[2]?.cash_register_entity_settings.sale_point) {
            this[this.GET_ACQUIRIG[2]?.acquiring_code as TAcquiringCode]['salePoint'] =
              this.GET_ACQUIRIG[2]?.cash_register_entity_settings.sale_point
          }
          if (this.taxAll) {
            this[this.GET_ACQUIRIG[2]?.acquiring_code as TAcquiringCode]['nds']['name'] =
              this.GET_ACQUIRIG[2]?.tax_name ?? ''
            this[this.GET_ACQUIRIG[2]?.acquiring_code as TAcquiringCode]['nds']['val'] =
              this.GET_ACQUIRIG[2]?.tax_id ?? ''
          }
          if (this.taxations) {
            this[this.GET_ACQUIRIG[2]?.acquiring_code as TAcquiringCode]['taxations']['name'] =
              this.GET_ACQUIRIG[2]?.taxation_system_name ?? ''
            this[this.GET_ACQUIRIG[2]?.acquiring_code as TAcquiringCode]['taxations']['val'] =
              this.GET_ACQUIRIG[2]?.taxation_system_id ?? ''
          }
        } else {
          // this.CloudPayments.public_id = this.GET_ACQUIRIG[2]?.public_id ?? ''
        }
        this.currencyTypes = this.GET_ACQUIRIG_SUPPORT?.currencyTypes
          ? this.GET_ACQUIRIG_SUPPORT.currencyTypes.map((e: ICurrencyTypes) => {
            return {
              name: e.writing,
              id: e.designation,
            }
          })
          : []

        const selectedCurrency = this.GET_ACQUIRIG[2]?.currency_type
        if (selectedCurrency) {
          this.currencyType.name = this.GET_ACQUIRIG[2]?.currency_writing
          this.currencyType.id = this.GET_ACQUIRIG[2]?.currency_type
        }
      } else {
        this.taxAll = this.GET_ACQUIRIG[2].tax_all
        this.currencyTypes = this.GET_ACQUIRIG_SUPPORT?.currencyTypes
          ? this.GET_ACQUIRIG_SUPPORT.currencyTypes.map((e: ICurrencyTypes) => {
            return {
              name: e.writing,
              id: e.designation,
            }
          })
          : []
        if (this.taxAll) {
          this[this.GET_ACQUIRIG_SUPPORT.data?.acquiringSelected?.name as TAcquiringCode]['nds'][
            'name'
            ] = this.GET_ACQUIRIG[2].acquiring_settings?.tax?.name
          this[this.GET_ACQUIRIG_SUPPORT.data?.acquiringSelected?.name as TAcquiringCode]['nds'][
            'val'
            ] = this.GET_ACQUIRIG[2]?.acquiring_settings?.tax?.id
        }
      }
    },

    selectValue(data: ISelectValueData): void {
      this.hasChanges = true
      this[data.element]['nds'] = { name: data.name, val: data.val.toString() }
    },

    selectTaxations(data: ISelectValueData): void {
      this.hasChanges = true
      this[data.element]['taxations'] = { name: data.name, val: data.val.toString() }
    },

    toggleConfirmModal(): void {
      this.confirmModal = !this.confirmModal
    },

    async checkForm(): Promise<void> {
      this.toggleConfirmModal()
      this.isLoading = true
      this.hasChanges = false

      const isB2p =
        this.selectPlatform.name === 'Best2pay' ||
        this.selectPlatform.name === 'BEST2PAY_BARREL' ||
        this.selectPlatform.name === 'CloudPayments'

      const getB2p =
        this.selectPlatform.name === 'Best2pay' ? 'Best2pay' : 'BEST2PAY_BARREL'

      const cashRegisterEntitySettings = {
        class_id: isB2p ? this[getB2p].classId : null,
        group_id: isB2p ? this[getB2p].groupId : null,
        sale_point: isB2p ? this[getB2p].salePoint : null,
      }

      const data: ISaveAcquiringPayload = {
        entity: 2,
        acquiring_id: this.selectPlatform.id,
        shop_id: this.id,
        currency_type: this.currencyType.id,
        acc_number: isB2p ? this[getB2p].accNumber : null,
        // public_id: isCloud ? this.CloudPayments.public_id : null,
        tax_id: isB2p && Number(this[getB2p].nds.val) !== 0 ? Number(this[getB2p].nds.val) : null,
        taxation_system_id: isB2p && Number(this[getB2p].taxations.val) !== 0 ? Number(this[getB2p].taxations.val) : null,
        cash_register_entity_settings: cashRegisterEntitySettings,
        token: this.token,
      }
      await this.SAVE_ACQUIRIG(data)

      await this.FETCH_ACQUIRIG({
        id: this.id,
        token: this.token,
      })
      this.isLoading = false
    },

    closeEntity() {
      this.$emit('onTwoLegalEntity', false)
      const data: {id: number, token: string} = {
        token: this.token,
        id: this.id
      }
      this.PUT_TWO_ENTITIES(data)
    },

    toggleModalCloseEnitity() {
      this.modalCloseEnitity = !this.modalCloseEnitity
    },

    onVModalCloud(): void {
      this.modalCloud = !this.modalCloud
    },

    async checkButton(): Promise<void> {
      this.isLoading = true
      const data: ISaveAcquiringPayload = {
        entity: 2,
        shop_id: this.id,
        // public_id: this.CloudPayments.public_id,
        token: this.token,
        currency_type: this.currencyType.id,
        acquiring_id: this.selectPlatform.id,
      }
      this.modalCloud = !this.modalCloud

      await this.SETUP_ACQUIRIG_SUPPORT({
        id: this.id,
        id_shop: this.selectPlatform.id,
        currency_type: this.currencyType.id,
        token: this.token,
      })
      await this.SAVE_ACQUIRIG(data)
      this.isLoading = false
    },
  }
})
