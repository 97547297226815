import { $authHost } from './api'
import { AxiosResponse } from 'axios'
import { IFiscalisationTypeResponse, IFiscalisationList, IChangeFiscalisationTypeResponse } from '@/types/fiscalistaionTypes'

class FiscalisationService {
  async fetchList(token: string): Promise<IFiscalisationList> {
    const { data }: AxiosResponse<IFiscalisationList> = await $authHost.get('/support/fiscalization-type/list', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    return data
  }

  async fetchFiscalisationType(token: string, id: string): Promise<IFiscalisationTypeResponse> {
    const { data }: AxiosResponse<IFiscalisationTypeResponse> = await $authHost.get(`/support/fiscalization-type/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    return data
  }

  async changeFiscalisationType(token: string, code: string, type: string): Promise<IChangeFiscalisationTypeResponse> {
    const { data }: AxiosResponse<IChangeFiscalisationTypeResponse> = await $authHost.post('/support/fiscalization-type',
    {
      code: code,
      type_fiscal: type
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    return data
  }
}

export default new FiscalisationService()
