
import { defineComponent, PropType } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import convertPhoneFormat from '@/utils/convertPhoneFormat'
import convertPhoneToFull from '@/utils/convertPhoneToFull'
import vButton from '@/components/v-button.vue'
import vInput from '@/components/v-input.vue'
import vSelect from '@/components/v-select.vue'
// import { Form } from 'vee-validate'
import vLightPreloader from '@/components/v-light-preloader.vue'
import vModalCloud from '@/components/modals/v-modal-cloud.vue'
import VModalConfirmationChangesDetails from '@/components/modals/v-modal-confirmation-changes-details.vue'
import VModalConfirmChanges from '@/components/modals/v-modal-confirm-changes.vue'
import VIconPlusInCircle from '@/assets/images/icons/v-icon-plus-in-circle.vue'
import { IAcquiringTaxAll, IAcquiringTaxations, IAcquiringSupport, ICurrencyTypes, IAcquirings } from '@/types/support'
import { ISaveAcquiringPayload, TCurrencyType } from '@/types/acquiring'

type TAcqiringCode = 'BEST2PAY_BARREL' | 'Best2pay'

interface ISelectValueData {
  name: string
  val: number
  id: number
  element: TAcqiringCode
}

interface IThisCurrencyTypes {
  name: string
  id: TCurrencyType
}

export default defineComponent ({
  name: 'VLogsPaySystems',
  components: {
    vButton,
    vInput,
    // Form,
    vSelect,
    vLightPreloader,
    vModalCloud,
    VModalConfirmChanges,
    VModalConfirmationChangesDetails,
    VIconPlusInCircle
  },
  props: {
    token: {
      type: String as PropType<string>,
      required: true,
      default: '',
    },
    id: {
      type: Number as PropType<number>,
      required: true,
      default: 0,
    },
    twoLegalEntity: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false,
    }
  },
  emits: ['onTwoLegalEntity'],
  data() {
    return {
      isLoading: true as boolean,
      platforms: [] as IAcquirings[],
      selectPlatform: {
        id: 0,
        name: '',
      },
      taxations: [] as IAcquiringTaxations[],
      taxAll: [] as IAcquiringTaxAll[],
      Best2pay: {
        classId: '',
        groupId: '',
        salePoint: '',
        accNumber: '',
        taxations: {
          name: '',
          val: '',
        },
        nds: {
          name: '',
          val: '',
        },
      },
      BEST2PAY_BARREL: {
        classId: '',
        groupId: '',
        salePoint: '',
        accNumber: '',
        taxations: {
          name: '',
          val: '',
        },
        nds: {
          name: '',
          val: '',
        },
      },
      CloudPayments: {
        public_id: '',
      },
      currencyTypes: [] as IThisCurrencyTypes[],
      currencyType: {
        name: '',
        id: '',
      },
      modalCloud: false,
      error: null,
      hasChanges: false,
      outsideClickData: null as EventTarget[] | null,
      confirmModal: false,
      modalChangingDetails: false,
      objectInput: {
        phone: '',
        inn: '',
        nameOrganization: '',
      },
      requisitesDisabled: true,
    }
  },
  computed: {
    ...mapGetters('acquiring', ['GET_ACQUIRIG', 'GET_ALERT', 'GET_DETAILS']),
    ...mapGetters('support', ['GET_ACQUIRIG_SUPPORT', 'GET_ACQUIRIG_ALERT', 'GET_PLATFORMS_DATA']),
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('restaurant', ['GET_SELECT_REST']),
    ...mapGetters('shops', ['GET_TWO_ENTITIES']),
    currencyTypeName(): string {
      return this.currencyType.name ? this.currencyType.name : ''
    },
    platformName(): string {
      return this.selectPlatform.name ? this.selectPlatform.name : ''
    },
    disabledCloudButton(): boolean {
      if (this.CloudPayments.public_id) {
        return !this.CloudPayments.public_id.length && !this.hasChanges
      } else {
        return true
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    disButtonBest2pay() {
      if (this.twoLegalEntity && this.GET_PLATFORMS_DATA?.data?.name === 'r-keeper') {
        if (
          !this.Best2pay.accNumber ||
          !this.Best2pay.accNumber.length ||
          !this.Best2pay.classId ||
          !this.Best2pay.classId.length ||
          !this.Best2pay.groupId ||
          !this.Best2pay.groupId.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      } else if (this.twoLegalEntity && this.GET_PLATFORMS_DATA?.data?.name === 'iiko') {
        if (
          !this.Best2pay.accNumber ||
          !this.Best2pay.accNumber.length ||
          !this.Best2pay.salePoint ||
          !this.Best2pay.salePoint.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      } else {
        if (
          !this.Best2pay.accNumber ||
          !this.Best2pay.accNumber.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      }
    },

    // eslint-disable-next-line vue/return-in-computed-property
    disButtonBarrel() {
      if (this.twoLegalEntity && this.GET_PLATFORMS_DATA?.data?.name === 'r-keeper') {
        if (
          !this.BEST2PAY_BARREL.accNumber ||
          !this.BEST2PAY_BARREL.accNumber.length ||
          !this.BEST2PAY_BARREL.classId ||
          !this.BEST2PAY_BARREL.classId.length ||
          !this.BEST2PAY_BARREL.groupId ||
          !this.BEST2PAY_BARREL.groupId.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      } else if (this.twoLegalEntity && this.GET_PLATFORMS_DATA?.data?.name === 'iiko') {
        if (
          !this.BEST2PAY_BARREL.accNumber ||
          !this.BEST2PAY_BARREL.accNumber.length ||
          !this.BEST2PAY_BARREL.salePoint ||
          !this.BEST2PAY_BARREL.salePoint.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      } else {
        if (
          !this.BEST2PAY_BARREL.accNumber ||
          !this.BEST2PAY_BARREL.accNumber.length ||
          !this.hasChanges
        ) {
          return true
        } else {
          return false
        }
      }
    },
  },
  watch: {
    objectInput: {
      deep: true,
      handler() {
        this.requisitesDisabled = false
      },
    },
    selectPlatform: {
      deep: true,
      handler() {
        const newTaxAll = this.GET_ACQUIRIG_SUPPORT.tax_all.filter(
          (i: IAcquiringTaxAll) => i.acquiring_id === this.selectPlatform.id
        )
        this.taxAll = newTaxAll

        const newTaxations = this.GET_ACQUIRIG_SUPPORT.taxations.filter(
          (i: IAcquiringTaxations) => i.acquiring_id === this.selectPlatform.id
        )
        this.taxations = newTaxations
      },
    },
  },
  async mounted() {
    await this.FETCH_PLATFORM({token: this.token, id: this.id})
    await this.gettingDetails()
    document.addEventListener('click', this.clickUotSide)
    try {
      this.isLoading = true
      await this.fetchAcquiringData()

      const data: {id: number, token: string} = {
        token: this.token,
        id: this.id
      }
      await this.CHECK_TWO_ENTITIES(data)
      if (this.GET_TWO_ENTITIES) {
        this.$emit('onTwoLegalEntity', true)
      } else {
        this.$emit('onTwoLegalEntity', false)
      }
    } catch (e) {
      console.error('error fetchAcquiringData', e)
    } finally {
      this.isLoading = false
    }

    if (Object.keys(this.GET_DETAILS[1]).length) {
      this.objectInput.nameOrganization = this.GET_DETAILS[1].name_organization
      this.objectInput.inn = this.GET_DETAILS[1].inn
      this.objectInput.phone = this.filterPhone(this.GET_DETAILS[1].phone)
      setTimeout(() => {
        this.requisitesDisabled = true
      }, 100)
    }
  },
  unmounted() {
    this.hasChanges = false
    document.removeEventListener('click', this.clickUotSide)
  },
  methods: {
    ...mapActions('acquiring', [
      'FETCH_ACQUIRIG',
      'SAVE_ACQUIRIG',
      'GETTING_DETAILS',
      'SAVING_DETAILS_INSTITUTION',
    ]),
    ...mapActions('support', [
      'FETCH_ACQUIRIG_SUPPORT',
      'SETUP_ACQUIRIG_SUPPORT',
      'FETCH_PLATFORM',
    ]),
    ...mapActions('shops', ['CHECK_TWO_ENTITIES', 'PUT_TWO_ENTITIES']),

    onModalChangingDetails(): void {
      this.modalChangingDetails = !this.modalChangingDetails
    },
    async gettingDetails(): Promise<void> {
      const data: {id: number, token: string} = {
        token: this.token,
        id: this.id
      }
      await this.GETTING_DETAILS(data)
    },
    filterPhone(phone: string): string {
      if (phone) {
        let ph = phone.replace(/\s/g, '')
        ph = ph.slice(1)
        return convertPhoneToFull(ph)
      }
      return ''
    },
    async savingDetailsInstitution(): Promise<void> {
      this.isLoading = true
      this.requisitesDisabled = true
      const res = {
        id: this.id,
        entity: 1,
        name_organization: this.objectInput.nameOrganization,
        inn: this.objectInput.inn,
        phone: convertPhoneFormat(this.objectInput.phone),
        token: this.GET_AUTHENTIFICATED,
      }
      await this.SAVING_DETAILS_INSTITUTION(res)
      this.isLoading = false
    },
    clickUotSide(e: Event) {
      this.outsideClickData = e.composedPath()
    },
    async fetchAcquiringData() {
      const response: IAcquiringSupport = await this.FETCH_ACQUIRIG_SUPPORT({
        id: this.id,
        token: this.token,
      })
      this.platforms = this.GET_ACQUIRIG_SUPPORT
        ? this.GET_ACQUIRIG_SUPPORT.acquirings
        : []
      if (response) {
        await this.FETCH_ACQUIRIG({
          id: this.id,
          token: this.GET_AUTHENTIFICATED,
        })

        this.selectPlatform.name = this.GET_ACQUIRIG[1]?.acquiring_code
        this.selectPlatform.id = this.GET_ACQUIRIG[1]?.acquiring_id

        this[this.GET_ACQUIRIG[1]?.acquiring_code as TAcqiringCode] = {
          classId: '',
          groupId: '',
          salePoint: '',
          accNumber: '',
          nds: {
            name: '',
            val: '',
          },
          taxations: {
            name: '',
            val: '',
          },
        }

        this.taxAll = this.GET_ACQUIRIG_SUPPORT.tax_all.filter(
          (i: IAcquiringTaxAll) => i.acquiring_id === this.GET_ACQUIRIG[1].acquiring_id
        )
        this.taxations = this.GET_ACQUIRIG_SUPPORT.taxations.filter(
          (i: IAcquiringTaxations) => i.acquiring_id === this.GET_ACQUIRIG[1].acquiring_id
        )

        if (
          this.GET_ACQUIRIG[1]?.acquiring_code === 'Best2pay' ||
          this.GET_ACQUIRIG[1]?.acquiring_code === 'BEST2PAY_BARREL'
        ) {
          if (this.GET_ACQUIRIG[1]?.acc_number) {
            this[this.GET_ACQUIRIG[1]?.acquiring_code as TAcqiringCode]['accNumber'] =
              this.GET_ACQUIRIG[1]?.acc_number
          }
          if (this.GET_ACQUIRIG[1]?.cash_register_entity_settings.class_id) {
            this[this.GET_ACQUIRIG[1]?.acquiring_code as TAcqiringCode]['classId'] =
              this.GET_ACQUIRIG[1]?.cash_register_entity_settings.class_id
          }
          if (this.GET_ACQUIRIG[1]?.cash_register_entity_settings.group_id) {
            this[this.GET_ACQUIRIG[1]?.acquiring_code as TAcqiringCode]['groupId'] =
              this.GET_ACQUIRIG[1]?.cash_register_entity_settings.group_id
          }
          if (this.GET_ACQUIRIG[1]?.cash_register_entity_settings.sale_point) {
            this[this.GET_ACQUIRIG[1]?.acquiring_code as TAcqiringCode]['salePoint'] =
              this.GET_ACQUIRIG[1]?.cash_register_entity_settings.sale_point
          }
          if (this.taxAll) {
            this[this.GET_ACQUIRIG[1]?.acquiring_code as TAcqiringCode]['nds']['name'] =
              this.GET_ACQUIRIG[1]?.tax_name ?? ''
            this[this.GET_ACQUIRIG[1]?.acquiring_code as TAcqiringCode]['nds']['val'] =
              this.GET_ACQUIRIG[1]?.tax_id ?? ''
          }
          if (this.taxations) {
            this[this.GET_ACQUIRIG[1]?.acquiring_code as TAcqiringCode]['taxations']['name'] =
              this.GET_ACQUIRIG[1]?.taxation_system_name ?? ''
            this[this.GET_ACQUIRIG[1]?.acquiring_code as TAcqiringCode]['taxations']['val'] =
              this.GET_ACQUIRIG[1]?.taxation_system_id ?? ''
          }
        } else {
          this.CloudPayments.public_id = this.GET_ACQUIRIG[1]?.public_id ?? ''
        }
        this.currencyTypes = this.GET_ACQUIRIG_SUPPORT?.currencyTypes
          ? this.GET_ACQUIRIG_SUPPORT.currencyTypes.map((e: ICurrencyTypes) => {
              return {
                name: e.writing,
                id: e.designation,
              }
            })
          : []

        const selectedCurrency = this.GET_ACQUIRIG[1]?.currency_type
        if (selectedCurrency) {
          this.currencyType.name = this.GET_ACQUIRIG[1]?.currency_writing
          this.currencyType.id = this.GET_ACQUIRIG[1]?.currency_type
        }
      } else {
        this.currencyTypes = this.GET_ACQUIRIG_SUPPORT?.currencyTypes
          ? this.GET_ACQUIRIG_SUPPORT.currencyTypes.map((e: ICurrencyTypes) => {
            return {
              name: e.writing,
              id: e.designation,
            }
          })
          : []

        this.taxAll = this.GET_ACQUIRIG[1].tax_all
        if (this.taxAll) {
          this[this.GET_ACQUIRIG_SUPPORT.data?.acquiringSelected?.name as TAcqiringCode]['nds'][
            'name'
          ] = this.GET_ACQUIRIG[1].acquiring_settings?.tax?.name
          this[this.GET_ACQUIRIG_SUPPORT.data?.acquiringSelected?.name as TAcqiringCode]['nds'][
            'val'
          ] = this.GET_ACQUIRIG[1]?.acquiring_settings?.tax?.id
        }

      }
    },
    selectValue(data: ISelectValueData): void {
      this.hasChanges = true
      this[data.element]['nds'] = { name: data.name, val: data.val.toString() }
    },
    selectTaxations(data: ISelectValueData): void {
      this.hasChanges = true
      this[data.element]['taxations'] = { name: data.name, val: data.val.toString() }
    },
    selectCurrency(payload: IThisCurrencyTypes): void {
      this.currencyType = payload
      this.hasChanges = true
    },
    async changePlatform(val: IAcquirings): Promise<void> {
      this.selectPlatform.id = val.id
      this.selectPlatform.name = val.name

      this.hasChanges = true
    },
    async checkForm(): Promise<void> {
      this.toggleConfirmModal()
      this.isLoading = true
      this.hasChanges = false

      const isB2p =
        this.selectPlatform.name === 'Best2pay' ||
        this.selectPlatform.name === 'BEST2PAY_BARREL'
      const isCloud = this.selectPlatform.name === 'CloudPayments'

      const getB2p =
        this.selectPlatform.name === 'Best2pay' ? 'Best2pay' : 'BEST2PAY_BARREL'

      const cashRegisterEntitySettings = {
        class_id: isB2p ? this[getB2p].classId : null,
        group_id: isB2p ? this[getB2p].groupId : null,
        sale_point: isB2p ? this[getB2p].salePoint : null,
      }

      const data: ISaveAcquiringPayload = {
        entity: 1,
        acquiring_id: this.selectPlatform.id,
        shop_id: this.id,
        currency_type: this.currencyType.id,
        acc_number: isB2p ? this[getB2p].accNumber : null,
        public_id: isCloud ? this.CloudPayments.public_id : null,
        tax_id: isB2p && Number(this[getB2p].nds.val) !== 0 ? Number(this[getB2p].nds.val) : null,
        taxation_system_id: isB2p && Number(this[getB2p].taxations.val) !== 0 ? Number(this[getB2p].taxations.val) : null,
        cash_register_entity_settings: cashRegisterEntitySettings,
        token: this.GET_AUTHENTIFICATED,
      }
      // await this.SETUP_ACQUIRIG_SUPPORT({
      //   id: this.id,
      //   id_shop: this.selectPlatform.id,
      //   currency_type: this.currencyType.id,
      //   token: this.token,
      // })
      await this.SAVE_ACQUIRIG(data)

      await this.FETCH_ACQUIRIG({
        id: this.id,
        token: this.GET_AUTHENTIFICATED,
      })
      this.isLoading = false
    },
    async checkButton(): Promise<void> {
      this.isLoading = true
      const data: ISaveAcquiringPayload = {
        entity: 1,
        shop_id: this.id,
        public_id: this.CloudPayments.public_id,
        token: this.GET_AUTHENTIFICATED,
        currency_type: this.currencyType.id,
        acquiring_id: this.selectPlatform.id,
      }
      this.modalCloud = !this.modalCloud

      await this.SETUP_ACQUIRIG_SUPPORT({
        id: this.id,
        id_shop: this.selectPlatform.id,
        currency_type: this.currencyType.id,
        token: this.token,
      })
      await this.SAVE_ACQUIRIG(data)
      this.isLoading = false
    },
    onVModalCloud(): void {
      this.modalCloud = !this.modalCloud
    },
    toggleConfirmModal(): void {
      this.confirmModal = !this.confirmModal
    },
    onTwoEntity() {
      const data: {id: number, token: string} = {
        token: this.token,
        id: this.id
      }
      this.PUT_TWO_ENTITIES(data)
      this.$emit('onTwoLegalEntity', true)
    }
  },
})
