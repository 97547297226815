<template>
  <div class="vLogsCastomization">
    <div class="vLogsCastomization__title">
      Стилизация
    </div>
    <div class="vLogsCastomization__buttons">
      <vRadioButton
        id="TAPPER"
        text="Tapper"
        :value="customType"
        name="customType"
        @changeData="changetipsType"
      />
      <vRadioButton
        v-for="item in customizationList"
        :id="item.code"
        :key="item.id"
        :text="item.name"
        :value="customType"
        name="customType"
        @changeData="changetipsType"
      />
    </div>

    <div class="vLogsCastomization__switches">
      <VLogsCustomisationSwitches
        id="serviceFee"
        v-model="serviceFeeCheck"
        :value="serviceFeeCheck"
        text="Сервисный сбор активен"
      />

      <VLogsCustomisationSwitches
        id="divideCheck"
        v-model="divideCheck"
        :value="divideCheck"
        text="Раздельный счёт"
      />
    </div>

    <vButton
      :fix-width="true"
      :disabled="!customType"
      @click="onConfirmModal"
    >
      Сохранить
    </vButton>



    <br>
    <br>
    <VDevider v-show="!isLoading" />
    <br>



    <div
      v-show="!isLoading"
      class="typeLicense__title"
    >
      Выбрать сервис на пречеке
    </div>
    <div
      v-show="!isLoading"
      class="vLogsCastomization__buttons"
    >
      <vRadioButton
        id="TIPS"
        text="Только чаевые"
        :value="precheckType"
        name="precheckType"
        @changeData="changePrecheckType"
      />
      <vRadioButton
        id="ORDER_AND_TIPS"
        text="Чаевые + заказ"
        :value="precheckType"
        name="precheckType"
        @changeData="changePrecheckType"
      />
    </div>

    <vButton
      v-show="!isLoading"
      :fix-width="true"
      @click="savePrecheck"
    >
      Сохранить
    </vButton>




    <vLightPreloader
      v-if="isLoading"
      :fixed="false"
      :transparent="true"
    />

    <vConfirmModal
      v-if="confirmModal"
      title="Вы уверены ?"
      @success="save"
      @cancel="onConfirmModal"
      @close="onConfirmModal"
    />
  </div>
</template>

<script setup>
import vConfirmModal from '@/components/modals/v-confirm-modal'
import vLightPreloader from '@/components/v-light-preloader'
import vRadioButton from '@/components/v-radio-button'
import vButton from '@/components/v-button'
import VLogsCustomisationSwitches from './v-logs-customisation-switches.vue'
import { useFeeType } from '@/stores/FeeType'
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useDivideCheckStore } from '@/stores/DivideCheckStore'
import VDevider from '@/components/v-devider.vue'
import TipsTypeService from '@/services/tipsType'


const store = useStore()
const FeeTypeStore = useFeeType()
const DivideCheckStore = useDivideCheckStore()

const isLoading = ref(true)
const customType = ref('TAPPER')
const serviceFeeCheck = ref(true)
const divideCheck = ref(true)
const confirmModal = ref(false)

const precheckType = ref('TIPS')

const props = defineProps({
  token: {
    type: String,
    required: true,
    default: '',
  },
  code: {
    type: String,
    required: true,
    default: '',
  },
  id: {
    type: Number,
    required: true,
    default: 0,
  }
})

//Getters
const customizationList = computed(() => store.getters['customization/GET_CUSTOMIZATION_LIST'])
const customization = computed(() => store.getters['customization/GET_CUSTOMIZATION'])
const storeFeeType = computed(() => FeeTypeStore.feeType)

//Actions
const fetchCustomizationList = (token) => store.dispatch('customization/FETCH_CUSTOMIZATION_LIST', token)
const fetchCustomizationByCode = (value) => store.dispatch('customization/FETCH_CUSTOMIZATION_BY_CODE', value)
const updateCustomization = (value) => store.dispatch('customization/UPDATE_CUSTOMIZATION', value)

//Methods
const changetipsType = (val) => {
  customType.value = val
}

const changePrecheckType = (val) => {
  precheckType.value = val
}

const onConfirmModal = () => {
  confirmModal.value = !confirmModal.value
}

const savePrecheck = async () => {
  isLoading.value = true
  await TipsTypeService.save(props.id, precheckType.value)
  setTimeout(() => {
    isLoading.value = false
  }, 2000);
}

const save = async() => {
  isLoading.value = true
  let customizationId = null
  if (customType.value !== 'TAPPER') {
    const [res] = customizationList.value.filter((i) => {
      if (i.code === customType.value) {
        return i.id
      }
    })
    customizationId = res.id
  }

  if (customType.value !== customization.value) {
    await updateCustomization({
      token: props.token,
      shopId: props.id,
      customizationId,
    })
  }

  if (serviceFeeCheck.value !== storeFeeType.value) {
    await FeeTypeStore.changeFeeType(props.id, props.token, serviceFeeCheck.value ? 'STANDARD' : 'WITHOUT_FEE')
  }

  if (divideCheck.value !== DivideCheckStore.divideCheck) {
    await DivideCheckStore.changeDivideCheck(props.id, props.token, divideCheck.value)
  }

  isLoading.value = false

  await dataPullUp()

  onConfirmModal()
}

const dataPullUp = async() => {
  await FeeTypeStore.fetchFeeType(props.id, props.token)
  await DivideCheckStore.fetchDivideCheck(props.id)
  await fetchCustomizationList(props.token)
  await fetchCustomizationByCode({
    token: props.token,
    code: props.code,
  })
  serviceFeeCheck.value = storeFeeType.value
  customType.value = customization.value
  divideCheck.value = DivideCheckStore.divideCheck
  setTimeout(() => {
    isLoading.value = false
  }, 500)
}

onMounted(async() => {
  await dataPullUp()
  const resTipsType = await TipsTypeService.fetch(props.id)
  if (resTipsType.success) {
    precheckType.value = resTipsType.result.selected
  }
})
</script>

<style lang="scss" scoped>
.vLogsCastomization {
  position: relative;
  width: 100%;
  height: 100%;
  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    *:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &__button {
    max-width: 500px;
  }

  &__switches {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 32px;
  }
}
</style>
