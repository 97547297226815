<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66667 13L18.3333 13L7.66667 13ZM13 0.999998C19.628 0.999999 25 6.372 25 13C25 19.628 19.628 25 13 25C6.372 25 1 19.628 1 13C1 6.372 6.372 0.999998 13 0.999998Z"
      stroke="#6764FF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
