import { defineStore } from "pinia"
import { ref } from "vue"
import FiscalisationService from '@/services/fiscalisationService'
import { AxiosError } from 'axios'
import { TFiscalisationType } from '@/types/fiscalistaionTypes'

export const useFiscalisationStore = defineStore('fiscalisationStore', () => {
  const fiscalisationType = ref<TFiscalisationType>('CLOUD_BEST2PAY')
  const fiscalisationList = ref<string[]>([])

  const fetchFiscalisationList = async (token: string): Promise<void> => {
    try {
      const response = await FiscalisationService.fetchList(token)
      fiscalisationList.value = response.result
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('fetchFiscalisationList', error.response);
      console.log('====================================');
    }
  }

  const fetchFiscalisationType = async (token: string, id: string): Promise<void> => {
    try {
      const response = await FiscalisationService.fetchFiscalisationType(token, id)
      fiscalisationType.value = response.result
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('fetchFiscalisationType', error.response);
      console.log('====================================');
    }
  }

  const changeFiscalisationType = async (token: string, code: string, type: TFiscalisationType): Promise<void> => {
    try {
      const response = await FiscalisationService.changeFiscalisationType(token, code, type)
      if (response.success) {
        fiscalisationType.value = type
      }
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('changeFiscalisationType', error.response);
      console.log('====================================');
    }
  }

  return {
    fiscalisationType,
    fiscalisationList,
    fetchFiscalisationList,
    fetchFiscalisationType,
    changeFiscalisationType
  }
})
